import downloadImage from "../../assests/images/Group.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AbsyzLogo from "../../assests/images/absyz logo.jpg";
import "./Versions.css";
import { useState } from "react";
import { Get, Post } from "../../services/apiServices";
import { networkUrls } from "../../services/networkUrls";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
const Versions = ({ projectDetails }: any) => {
  const [versionData, setVersionData] = useState<any>([]);


  function convertImageToBase64(url: any, callback: any) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const regionCurrencyNew = (regionName: any) => {
    const region = projectDetails[0]?.currencyDetails;
    return region ? region.region_value : null;
  };

  const generatePDF = (
    projectDetails: any,
    projectSumDetails: any,
    imageData: string,
    versionInfo:any
  ) => {
    const regionCurrency = (regionName: any) => {
      const region = projectDetails?.currencyDetails;
      return region ? region.region_value : null;
    };
  
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const doc = new jsPDF(orientation, unit, size);
    // Start Y position for content
    let yPos = 20;
  
    // Add title
    doc.setFontSize(12);
    const imageX = 275; // X coordinate
    const imageY = 30; // Y coordinate
    const imageWidth = 80; // Width of the image
    const imageHeight = 60; // Height of the image
  
    doc.addImage(imageData, "JPEG", imageX, imageY, imageWidth, imageHeight);
    doc.text("PROJECT ESTIMATION", 250, 100);
  
    doc.text(`Project Name : ${projectDetails.project_name}`, 38, 140);
    doc.text(`Project Type : ${projectDetails.project_type}`, 38, 160);
    doc.text(`Project Region : ${projectDetails.regionDetails.name}`, 38, 180);
    doc.text(
      `Standard Quote(Average) : ${regionCurrency(
        projectDetails?.regionDetails?.name
      )} ${projectDetails.projectItems[0].std_average_price.toLocaleString()}`,
      38,
      200
    );
    doc.text(
      `Minimum Quote(Average) : ${regionCurrency(
        projectDetails?.regionDetails?.name
      )} ${projectDetails.projectItems[0].min_average_price.toLocaleString()}`,
      38,
      220
    );
    doc.text(
      `Maximum Discount : ${projectDetails.projectItems[0].maximum_discount} %`,
      38,
      240
    );
    
    // Adjust yPos to avoid overlap
    yPos = 260;
  
    doc.text(`Version : ${versionInfo.version_no}`, 38, yPos);
    
    // Add some space before the timestamps
    yPos += 20;
  
    doc.text(`Created At : ${moment(versionInfo.created_at).format('DD MMMM YYYY hh:mm:ss a')}`, 38, yPos);
    yPos += 20;
    doc.text(`Updated At : ${moment(versionInfo.updated_at).format('DD MMMM YYYY hh:mm:ss a')}`, 38, yPos);
  
    // Adjust the yPos for the table to have some space
    yPos += 20;
  
    // Table headers and data
    const headers = [
      "Stream",
      "Level",
      "Total resources",
      "Shift",
      "Onsite",
      "Budgeted hours",
      "Per hour(Standard price)",
      "Total(Standard price)",
      "Per hour(Minimum price)",
      "Total(Minimum price)",
    ];
    const tableData: any = [];
  
    const arrayProjectDetails = [projectDetails];
    arrayProjectDetails.forEach((project: any) => {
      project.projectItems?.forEach((item: any) => {
        tableData.push([
          item.projectStreams?.name || "",
          item.projectLevels?.name || "",
          item.no_of_resources.toString(),
          item.shift?.name,
          item.onsite === true ? "Yes" : "No",
          item.total_budgeted_hours.toString(),
          `${regionCurrency(project.regionDetails.name)} ${
            item.std_per_hr_rate
          }`,
          `${regionCurrency(
            project.regionDetails.name
          )} ${item.std_total_hr_rate?.toLocaleString()}`,
          `${regionCurrency(project.regionDetails.name)} ${
            item.min_per_hr_rate
          }`,
          `${regionCurrency(
            project.regionDetails.name
          )} ${item.min_total_hr_rate?.toLocaleString()}`,
        ]);
      });
    });
    // Add totals row
    const totalsRow = [
      "Totals",
      "",
      projectSumDetails.no_of_resources.toString(),
      "",
      "",
      projectSumDetails.total_budgeted_hours.toString(),
      "",
      `${regionCurrency(projectDetails.regionDetails?.name)} ${
        projectSumDetails.std_total_hr_rate?.toLocaleString()
      }`,
      "",
      `${regionCurrency(projectDetails.regionDetails?.name)} ${
        projectSumDetails.min_total_hr_rate?.toLocaleString()
      }`,
    ];
    tableData.push(totalsRow);
  
    const startX = -20;
    const startY = yPos + 10;
  
    // Define column widths
    const columnWidths = [50, 30, 45, 40, 30, 45,  82, 72, 83, 73];  
    // Create table with adjusted column widths
  
    const styles = {
      theme: "grid",
      fontSize: 8,
      cellPadding: 2,
      // textColor: [26, 48, 93],
      headStyles: {
        fillColor: [26, 48, 93],
        textColor: 255,
        fontSize: 10,
        halign: "center",
      },
      bodyStyles: {
        textColor: [26, 48, 93],
        fontSize: 8,
        valign: "middle",
        halign: "center",
      },
      alternateRowStyles: { fillColor: [245, 245, 245] },
      valign: "middle",
      halign: "center",
    };
    //@ts-ignore
    doc.autoTable({
      startX,
      startY,
      head: [headers],
      body: tableData,
      theme: "grid",
      margin: { top: 20 },
      styles,
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] },
        5: { cellWidth: columnWidths[5] },
        6: { cellWidth: columnWidths[6] },
        7: { cellWidth: columnWidths[7] },
        8: { cellWidth: columnWidths[8] },
        9: { cellWidth: columnWidths[9] },
      },
    });
  
    // Save the PDF with a specific name
    doc.save(`${projectDetails.project_name}-RateCard-V${versionInfo.version_no}.pdf`);

  };
  

  const handleDownload = async (item: any) => {
    const payload = {
      version_id: item.id,
      project_id: item.project_id,
    };

    try {
      const response = await Post(networkUrls.getVersion, payload, true);
      if (response?.data?.api_status === 200) {
        const projectDetails =
          response?.data?.data?.project_costing?.data?.projectsFromDB;
        const projectSumDetails =
          response?.data?.data?.project_costing?.data?.sums;
        setVersionData(projectDetails);
       
        const versionInfo=response?.data?.data
       

        convertImageToBase64(AbsyzLogo, function (base64Image: any) {
          generatePDF(projectDetails, projectSumDetails, base64Image,versionInfo);
        });
      }
    } catch (error) {
      console.log("Error getting version data", error);
    }
  };
  
  return (
    <>
      {projectDetails[0].versiondetails?.length ? (
      <>
      <small style={{color :"#00000099"}}>* Latest version will be displayed first</small>
       <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
          <Table className="content" style={{ border: "none" }}>
            <TableHead className="blue">
              <TableRow>
                <TableCell align="center">Versions</TableCell>
                <TableCell align="center">Number of resources</TableCell>
                <TableCell align="center">Standard price</TableCell>
                <TableCell align="center">Minimum price</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectDetails.map((project: any, projectIndex: any) =>
                project.versiondetails?.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell align="center">{item?.version_no}</TableCell>
                    <TableCell align="center">
                      {item?.project_costing?.data?.sums?.no_of_resources}
                    </TableCell>
                    <TableCell align="center">
                      <span>
                        {item?.project_costing?.data?.projectsFromDB?.currencyDetails?.region_value}{" "}
                        {item?.project_costing?.data?.sums?.std_total_hr_rate?.toLocaleString()}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span>
                      {item?.project_costing?.data?.projectsFromDB?.currencyDetails?.region_value}{" "}
                        {item?.project_costing?.data?.sums?.min_total_hr_rate?.toLocaleString()}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={downloadImage}
                        alt="download"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDownload(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer></>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height :"34vh"
          }}
        >
          No versions found
        </div>
      )}
    </>
  );
};

export default Versions;
