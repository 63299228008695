import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import { Button } from "@mui/joy";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";


export default function ReuseableModal({ open, setOpen ,type,setIsUpdate}: any) {

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: type==="update" ? 430 : 350,
    bgcolor: "background.paper",
    border: "2px solid #EBEFF5",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove("user_role");
    Cookies.remove("employee_id");
    Cookies.remove("salesforce_token");
    Cookies.remove("accessToken");
    Cookies.remove("super_admin");
    Cookies.remove("user_role");
    navigate("/login");
  };

  const handleUpdate=()=>{
    setIsUpdate(true)
    setOpen(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            sx={{
              display: "flex",
              flexDirection:"column",
              justifyContent:"space-between",
              alignItems: "center",
              position:"relative"
            }}
          >
            <span style={{ cursor: "pointer",paddingBottom:"20px" }} onClick={handleClose}>
              <CloseIcon  sx={{
                cursor: "pointer",
                position: "absolute",
                top: 0,
                right: 4,
              }}/>
            </span>
            <h3 id="modal-modal-title" style={{fontWeight : 500 , fontFamily :"Robot, Helvetica, Arial , sans-serif"}}>
             {type==="update" ? "Are you sure you want to update the Opportunity?" : "Are you sure want to logout?"} 
            </h3>
            
          </Grid>
          <Grid sx={{ display: "flex", gap: 2, justifyContent:"center", marginTop: "15px" }}>
            {type==="update" ? <>
            <Button variant="outlined" onClick={handleClose} >Cancel</Button>
            <Button onClick={handleUpdate}>Ok</Button>
            </> : 
            
            <Button onClick={logout}>Logout</Button>
          }
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
