import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, CircularProgress } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import "./FileUpload.css";
import axios from "axios";
import ReuseableSnackbar from "../../../components/common/Snackbar/ResueableSnackbar";
import { BaseURL, getHeaders } from "../../../services/apiServices";
import { useNavigate } from "react-router-dom";
import { getJwt } from "../../../utils/helpers/getJwt";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// import samplesheet from "../../../../"
const SheetJSFT = ".xlsx, .xls"; // Define acceptable file types

export const FileUploadComponent = () => {
  const [fileMerge, setFileMerge] = useState<any>();
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [isLoading, setIsLoading] = useState(false); // State for progress indicator
  const [error, setError] = useState(""); // State for error message
  const navigate = useNavigate();

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    setFileMerge(file);
  };

  const handleDownload = () => {
    // Sample data for Excel
    const data = [
      { Platform: 'Salesforce', TC01: 300,TC02:750, TC03:400,TC04:450,TC05:200,TC06:800,TC07:900,TC08:1600,TC09:1600 },
      { Platform: 'PM', TC01: 300,TC02:750, TC03:400,TC04:450,TC05:200,TC06:800,TC07:900,TC08:1600,TC09:1600 },
      { Platform: 'Digital', TC01: 300,TC02:750, TC03:400,TC04:450,TC05:200,TC06:800,TC07:900,TC08:1600,TC09:1600 },
      { Platform: 'QA', TC01: 300,TC02:750, TC03:400,TC04:450,TC05:200,TC06:800,TC07:900,TC08:1600,TC09:1600 },
      { Platform: 'BA', TC01: 300,TC02:750, TC03:400,TC04:450,TC05:200,TC06:800,TC07:900,TC08:1600,TC09:1600 },
      { Platform: 'Mulesoft', TC01: 450,TC02:550, TC03:700,TC04:900,TC05:1100,TC06:1400,TC07:1600,TC08:1600,TC09:1600 },

    ];

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string and trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Create an anchor element and trigger the download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sample.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleUploadClick = async () => {
    setIsLoading(true); 
    const formData = new FormData();
    formData.append("file", fileMerge);
    try {
      const response = await axios.post(
        `${BaseURL}/admin/uploadexcel`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getJwt()}`,
          },
        }
      );
      setFileMerge(undefined);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear file input
      }
      if (response.data.statusCode === 200) {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "success",
          message: response.data.message,
        });
      }
    } catch (error) {
      setIsShowSnackbar(true);
      setSnackbarData({
        color: "error",
        message: "Error uploading file",
      });
    } finally {
      setIsLoading(false); // Hide progress indicator after upload completes
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <strong>Upload File: Please upload .xlsx format files only.</strong>
      <Box
        sx={{
          height: "80px",
        }}
      >
        <Grid
          container
          alignItems="center"
          style={{
            marginTop: "20px",
          }}
        >
          <Grid item xs={3}>
            <input
              type="file"
              name="file"
              className="upload-box"
              accept={SheetJSFT}
              ref={fileInputRef}
              style={{
                fontSize: "0.8em",
                height: "20px",
                fontFamily: "Poppins",
              }}
              onChange={handleChange}
            />
            {error && <small style={{ color: "#d32f2f" }}>{error}</small>}
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginBottom: error ? "16px" : "0",
            }}
          >
            <Button
              type="button"
              style={{ marginLeft: "30px" }}
              variant="contained"
              startIcon={<FileUploadOutlined />}
              endIcon={
                isLoading && (
                  <CircularProgress size={24} style={{ color: "white" }} />
                )
              }
              disabled={isLoading || !fileMerge?.name?.length}
              onClick={handleUploadClick}
            >
              Upload File to One Drive
            </Button>
          </Grid>
          
        </Grid>
        <Grid sx={{marginTop:"20px"}}>
            <Button variant="contained" onClick={handleDownload}>Download Sample sheet</Button>
          </Grid>
      </Box>
      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}
    </div>
  );
};
