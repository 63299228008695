import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, IconButton, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/joy/CircularProgress";
import { Get, Post, Put } from "../../../services/apiServices";
import { networkUrls } from "../../../services/networkUrls";
import ReusableButton from "../../../components/common/ReuseableButton/ReusableButton";
import ReuseableSnackbar from "../../../components/common/Snackbar/ResueableSnackbar";
import DeleteIcon from "../../../assests/images/Delete.png";

interface DropdownOption {
  region_value: any;
  id?: number;
  name: string;
  region?: string;
}

interface FormData {
  oHCost: string;
  workingDays: string;
  utilization: string;
  billableResources: string;
  internCost: string;
  regionConfig: any;
  perDiem: any;
}

const ProjectEstimator: React.FC = () => {
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dropdowns, setDropdowns] = useState<Record<string, DropdownOption[]>>(
    {}
  );
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [allConfigs, setAllConfigs] = useState<any[]>([]);
  const [dynamicFields, setDynamicFields] = useState(0);
  const [showFields, setShowFields] = useState<Array<boolean>>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    oHCost: "",
    billableResources: "",
    utilization: "",
    internCost: "",
    regionConfig: [],
    workingDays: "",
    perDiem: "",
  });

  const [errors, setErrors] = useState({
    oHCost: "",
    billableResources: "",
    utilization: "",
    internCost: "",
    regionConfig: [] as any[],
    workingDays: "",
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    levelIndex?: number,
    fieldIndex?: number
  ) => {
    const { name, value } = event.target;

    setFormData((prevFormData: any) => {
      const updatedConfig = [...prevFormData.regionConfig];
      if (levelIndex !== undefined && fieldIndex !== undefined) {
        const updatedFields = [...updatedConfig[levelIndex].fields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          [name]: value,
        };
        updatedConfig[levelIndex] = {
          ...updatedConfig[levelIndex],
          fields: updatedFields,
        };
      } else if (levelIndex !== undefined) {
        updatedConfig[levelIndex] = {
          ...updatedConfig[levelIndex],
          [name]: value,
        };

        if (name === "region") {
          const currency = regionCurrency(value);
          updatedConfig[levelIndex].currency = currency;
          updatedConfig[levelIndex].isAdded = false;
        }
      } else {
        (prevFormData as any)[name] = value;
      }

      return { ...prevFormData, regionConfig: updatedConfig };
    });

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
      regionConfig: [
        ...prevErrors.regionConfig.slice(
          0,
          levelIndex !== undefined ? levelIndex : 0
        ),
        {
          ...prevErrors.regionConfig[levelIndex !== undefined ? levelIndex : 0],
          [name]: "",
        },
        ...prevErrors.regionConfig.slice(
          levelIndex !== undefined ? levelIndex + 1 : 1
        ),
      ],
    }));
  };

  const getAllConfigurations = async (dropdownData?: any) => {
    try {
      const response = await Get(networkUrls.getAllConfigurations, true);
      if (response?.data?.api_status === 200) {
        setAllConfigs(response?.data?.data);
        if (response?.data?.data?.length) {
          setIsEditMode(true);
          setFormData((prev: any) => ({
            ...prev,
            workingDays: response.data.data[0].working_days_year,
            billableResources: response.data.data[0].billable_resources,
            utilization: response.data.data[0].utilization,
            oHCost: response.data.data[0].oh_cost,
            internCost: response.data.data[0].intern_cost,
            regionConfig: getFormattedRegions(
              response.data.data[0].configRegions
            ),
          }));

          const configLength = response.data.data[0].configRegions?.length;
          const dropdownLength = dropdownData?.region?.length;
          if (configLength === dropdownLength) {
            setAddBtnDisabled(true);
          }
          const updatedShowFields: any = Array(
            response.data.data[0].configRegions.length
          ).fill(true);
          setShowFields([...showFields, ...updatedShowFields]);
        } else {
        }
      }
    } catch (error) {
      console.log(error, "Error getting configurations");
    }
    setIsLoading(false);
  };

  const getAllDropDowns = async () => {
    try {
      const response = await Get(networkUrls.getDropdownValues, true);
      if (response?.data?.api_status === 200) {
        setDropdowns(response?.data?.data);
        return response?.data?.data;
      }
    } catch (error) {
      console.log("Error getting Dropdown values", error);
    }
  };
  useEffect(() => {
    handleFormData();
    const fetchData = async () => {
      try {
        const response = await getAllDropDowns();
        await getAllConfigurations(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getFormattedRegionsPayload = (regions: any) => {
    let filteredRegions: any;
    if (allConfigs.length) filteredRegions = regions;
    else
      filteredRegions = regions.filter(
        (_: any, index: number) => index !== regions.length - 1
      );
    const updatedRegions = filteredRegions.map((item: any, index2: number) => ({
      region_id: getRegionId(item.region),
      min_margin: item.minimumMargin,
      std_margin: item.standardMargin,
      per_diem: Number(item.perDiem),
      exchange_rate:
        formData?.regionConfig[index2]?.region === "INDIA"
          ? 0
          : item.exchangeRate,
    }));
    return updatedRegions;
  };

  const getRegionId = (region: string) => {
    const id = dropdowns.region.find((r) => r.name === region)?.id;
    return id;
  };

  function hasDuplicates(array: any) {
    const regions = array.map((item: any) => item.region);
    const uniqueIds = new Set(regions);
    return regions.length !== uniqueIds.size;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateFields()) return;
    setLoader(true);
    const payLoad: any = {
      working_days_year: formData?.workingDays,
      billable_resources: formData?.billableResources,
      utilization: formData?.utilization,
      oh_cost: formData?.oHCost,
      intern_cost: formData.internCost,
      config: getFormattedRegionsPayload(formData.regionConfig),
    };

    if (allConfigs.length !== 0) {
      payLoad.calculation_id = allConfigs[0].configRegions[0].calculation_id;
    }
    let response: any;
    if (allConfigs.length === 0) {
      response = await Post(networkUrls.createConfig, payLoad, true);
      if (response.data.api_status === 200) {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "success",
          message: response?.data?.message,
        });
        getAllConfigurations();
      } else {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "error",
          message: response?.data?.message,
        });
      }
    } else {
      response = await Put(networkUrls.editConfig, payLoad, true);
      if (response.response.api_status === 200) {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "success",
          message: response?.response?.message,
        });
        getAllConfigurations();
      } else {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "error",
          message: response?.response?.message,
        });
      }
    }
    setLoader(false);
  };

  const validateFields = (): boolean => {
    const newErrors: any = {
      oHCost: "",
      billableResources: "",
      internCost: "",
      utilization: "",
      regionConfig: [],
      workingDays: "",
    };

    if (!formData.oHCost) {
      newErrors.oHCost = "OHCost is required";
    }

    if (!formData.billableResources) {
      newErrors.billableResources = "BillableResources is required";
    }
    if (!formData.utilization) {
      newErrors.utilization = "Utilization is required";
    }

    if (!formData.internCost) {
      newErrors.internCost = "Intern Cost is required";
    }
    if (!formData.workingDays) {
      newErrors.workingDays = "Working Days is required";
    }

    formData.regionConfig.forEach((config: any, index: number) => {
      const configErrors = {
        region: "",
        minimumMargin: "",
        standardMargin: "",
        exchangeRate: "",
        perDiem: "",
      };
      if (
        index === formData.regionConfig.length - 1 &&
        allConfigs.length === 0
      ) {
        return;
      }

      if (!config.region) {
        configErrors.region = "Region is required";
      }

      if (!config.minimumMargin) {
        configErrors.minimumMargin = "Mimimum Margin is required";
      }
      if (!config.perDiem) {
        configErrors.perDiem = "Perdiem  is required";
      }
      if (config.minimumMargin >=config.standardMargin) {
        configErrors.standardMargin =
          "Standard Margin should be greater than minimum margin";
      }

      console.log(config.region,"region")

      if(config.exchangeRate<10){
        configErrors.exchangeRate = "Exchange rate value must be 10 or more than 10"
      }

      if(config.perDiem<10){
        configErrors.perDiem = "Perdiem value must be 10 or more than 10"
      }

      if (!config.standardMargin) {
        configErrors.standardMargin = "Standard Margin is required";
      }
      if (config.region !== "INDIA") {
        if (!config.exchangeRate) {
          configErrors.exchangeRate = "Exchange Rate is required";
        }
      }

      newErrors.regionConfig[index] = configErrors;
    });

    setErrors(newErrors);

    const hasErrors =
      Object.values(newErrors).some(
        (fieldErrors) => fieldErrors && typeof fieldErrors === "string"
      ) ||
      newErrors.regionConfig.some((configErrors: any) =>
        Object.values(configErrors).some((error) => error)
      );
    if (hasErrors) return false;
    if (formData.regionConfig.length > 2 || allConfigs.length) {
      let isDuplicate: any = hasDuplicates(formData.regionConfig);
      if (isDuplicate) {
        setIsShowSnackbar(true);
        setSnackbarData({
          message: "Please don't update duplicate regions",
          color: "error",
        });
        return false;
      }
    }
    return !hasErrors;
  };

  const handleDigitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: any
  ) => {
    const { value } = event.target;
    if (/^[0-9]*$/.test(value)) {
      handleChange(event, index);
    }
  };

  const handleDigitExchangeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: any
  ) => {
    const { value } = event.target;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      handleChange(event, index);
    }
  };

  const handleDigitPercentage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: any
  ) => {
    const { value } = event.target;
    if (/^[0-9]*$/.test(value) && (value === "" || parseInt(value) <= 100)) {
      handleChange(event, index);
    }
  };

  const getFormattedRegions = (regions: any) => {
    const updtedRegions = regions.map((item: any) => ({
      region: item.calculationValues.name,
      minimumMargin: item.min_margin,
      standardMargin: item.std_margin,
      exchangeRate: item.exchange_rate,
      perDiem: item.per_diem,
      currency: item.calculationValues.region_value,
      isAdded: false,
    }));
    return updtedRegions;
  };

  const handleFormData = () => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      regionConfig: [
        ...prevFormData.regionConfig,
        {
          region: "",
          minimumMargin: "",
          standardMargin: "",
          exchangeRate: "",
          level: "",
          perDiem: "",
        },
      ],
    }));
    setShowFields([...showFields, true]);
  };
  const addDynamicFields = () => {
    if (formData?.regionConfig?.length === dropdowns?.regions?.length) {
      setAddBtnDisabled(true);
     return;
    }
    setDynamicFields((prev: any) => prev + 1);
    handleFormData();
  };
  const handleDeleteFields = (index: number) => {
    const updatedFields = [...formData.regionConfig];
    updatedFields.splice(index, 1);
    setFormData({ ...formData, regionConfig: updatedFields });

    const updatedShowFields = [...showFields];
    updatedShowFields.splice(index, 1);
    setShowFields(updatedShowFields);
  };

  const regionCurrency = (regionName: any) => {
    const region = dropdowns?.region?.find((data) => data.name === regionName);
    return region ? region.region_value : null;
  };

  const handleEditor = () => {
    if (formData?.regionConfig?.length === dropdowns?.region?.length) {
      setAddBtnDisabled(true);
    }
    setIsEditMode(false);
  };


  return (
    <div style={{ marginTop: "20px" }}>
      {isloading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "72vh", // Example height to center vertically
          }}
        >
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <>
          <Grid
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              // marginRight: "100px",
              width: "100%",
            }}
          >
            {allConfigs.length !== 0 && (
              <ReusableButton
                text="Update  Configuration"
                style={{ backgroundColor: "rgb(25, 118, 210)" }}
                onClick={handleEditor}
              />
            )}
          </Grid>

          <Box
            sx={{
              flexGrow: 1,
              marginBottom: "15px",
              // backgroundColor: "aliceblue",
              // padding: "12px",
              // width: "95%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Working Days in a Year"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="workingDays"
                    value={formData.workingDays}
                    error={!!errors.workingDays}
                    helperText={errors.workingDays}
                    onChange={(event) => handleDigitChange(event, undefined)}
                    disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Billable Resources"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="billableResources"
                    value={formData.billableResources}
                    error={!!errors.billableResources}
                    helperText={errors.billableResources}
                    onChange={(event) => handleDigitChange(event, undefined)}
                    disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Utilization"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="utilization"
                    value={formData.utilization}
                    error={!!errors.utilization}
                    helperText={errors.utilization}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ), // Example adornment
                    }}
                    onChange={(event) =>
                      handleDigitPercentage(event, undefined)
                    }
                    disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="OH Cost"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="oHCost"
                    value={formData.oHCost}
                    onChange={(event) => handleDigitChange(event, undefined)}
                    error={!!errors.oHCost}
                    disabled={isEditMode}
                    helperText={errors.oHCost}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Intern Cost"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="internCost"
                    value={formData.internCost}
                    error={!!errors.internCost}
                    helperText={errors.internCost}
                    disabled={isEditMode}
                    onChange={(event) => handleDigitChange(event, undefined)}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    onClick={addDynamicFields}
                    endIcon={<AddIcon />}
                    variant="contained"
                    disabled={isEditMode || addBtnDisabled}
                  >
                    Add Region Configuration
                  </Button>
                </Grid>
                {formData?.regionConfig?.map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    {showFields[index] && (
                      <>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            select
                            label="Region"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="region"
                            value={item?.region}
                            onChange={(event) => handleChange(event, index)}
                            error={!!errors.regionConfig[index]?.region}
                            helperText={errors.regionConfig[index]?.region}
                            disabled={isEditMode}
                          >
                            {dropdowns?.region?.length > 0 ? (
                              dropdowns.region.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No options available</MenuItem>
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1.65}>
                          <TextField
                            label="Currency"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="currency"
                            value={formData.regionConfig[index]?.currency || ""}
                            disabled
                            // onChange={(event) => handleChange(event, index)}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1.75}>
                          <TextField
                            label={`Perdiem${formData.regionConfig[index]?.currency ? `(${formData.regionConfig[index]?.currency})` : ""}`}
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="perDiem"
                            value={item.perDiem}
                            onChange={(event) =>
                              handleDigitChange(event, index)
                            }
                            inputProps={{
                              maxLength: 6,
                            }}
                            error={!!errors.regionConfig[index]?.perDiem}
                            helperText={errors.regionConfig[index]?.perDiem}
                            disabled={isEditMode}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            label="Min Margin(%)"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="minimumMargin"
                            value={item.minimumMargin}
                            onChange={(event) =>
                              handleDigitPercentage(event, index)
                            }
                            error={!!errors.regionConfig[index]?.minimumMargin}
                            helperText={
                              errors.regionConfig[index]?.minimumMargin
                            }
                            disabled={isEditMode}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            label="Std Margin(%)"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="standardMargin"
                            value={item.standardMargin}
                            onChange={(event) =>
                              handleDigitPercentage(event, index)
                            }
                            error={!!errors.regionConfig[index]?.standardMargin}
                            helperText={
                              errors.regionConfig[index]?.standardMargin
                            }
                            disabled={isEditMode}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1.9}>
                          <TextField
                            label="Exchange Rate"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="exchangeRate"
                            value={
                              item.region === "INDIA" ? "0" : item.exchangeRate
                            }
                            disabled={
                              item.region === "INDIA" || isEditMode
                                ? true
                                : false
                            }
                            // value={ item.region === "INDIA" ? 0 : dropdowns.region[0].name === "INDIA" ? 0 : item.exchangeRate}
                            // disabled = {item.region === "INDIA" ? true : false}
                            onChange={(event) =>
                              handleDigitExchangeChange(event, index)
                            }
                            error={ item.region!=="INDIA" && !!errors.regionConfig[index]?.exchangeRate}
                            helperText={
                              item.region!=="INDIA" &&  errors.regionConfig[index]?.exchangeRate
                            }
                          />
                        </Grid>
                        {index !== 0 &&
                          (item.isAdded === undefined || item.isAdded) && (
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "10px",
                                marginTop: "20px",
                              }}
                            >
                              <IconButton
                                onClick={() => handleDeleteFields(index)}
                                disabled={isEditMode}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="delete_icon"
                                  height="25px"
                                  width="25px"
                                  // style={{ marginRight: "8px" }}
                                />{" "}
                              </IconButton>
                            </Grid>
                          )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Grid>

              <Grid
                container
                spacing={3}
                justifyContent="center"
                sx={{ marginTop: "15px" }}
              >
                <Grid item>
                  <ReusableButton
                    text={allConfigs.length ? "Save" : "Create"}
                    //   style={{ backgroundColor: "#4CAF50" }}
                    endIcon={loader && <CircularProgress size="sm" />}
                    type="submit"
                    disabled={isEditMode}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </>
      )}

      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}
    </div>
  );
};

export default ProjectEstimator;
