import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assests/images/Delete.png";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { networkUrls } from "../../services/networkUrls";
import { Get, Put } from "../../services/apiServices";
import AddIcon from "@mui/icons-material/Add";
import ReuseableSnackbar from "../../components/common/Snackbar/ResueableSnackbar";
import { CircularProgress } from "@mui/joy";
import ReuseableModal from "../../components/common/Modal/Modal";
import { compareMultiplePairs } from "../../utils/helpers/constants";

const useStyles = makeStyles({
  root: {
    "& .MuiCheckbox-root .MuiSvgIcon-root": {
      color: "#1670C3",
      borderRadius: "1px",
    },
    "& .Mui-checked .MuiSvgIcon-root": {},
  },
});
const dropdowniconstyles = {
  ".MuiSelect-icon": {
    color: "#1670C3",
  },
};
interface DropdownOption {
  id?: number;
  name: string;
  region?: string;
}

interface CheckedItems {
  [key: string]: boolean;
}

interface UpdatedField {  

    
  level: string;
  no_of_resources: number;
  total_budgeted_hours: number;
  shift: any;
  onsite: boolean;
}

interface StreamFields {
  [key: string]: UpdatedField[];
}

interface ProjectDetail {
  id: number;
  project_name: string;
  project_type: string;
  region: string;
  projectItems?: ProjectItems[];
  regionDetails: {
    id: number;
    name: string;
    region_value: string;
  };
}
interface ProjectItems {
  id: number;
  project_id: number;
  level: number;
  stream: number;
  no_of_resources: number;
  min_average_price: number;
  min_per_hr_rate: number;
  min_total_hr_rate: number;
  std_average_price: number;
  std_per_hr_rate: number;
  std_total_hr_rate: number;
  total_budgeted_hours: number;
  maximum_discount: number;
  status: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  projectStreams: ProjectStreams;
  projectLevels: ProjectLevels;
  shift_id: number;
  onsite: boolean;
}

interface ProjectStreams {
  id: number;
  name: string;
}

interface ProjectLevels {
  id: number;
  name: string;
}
interface ProjectSumDetail {
  no_of_resources: number;
  total_budgeted_hours: number;
  min_total_hr_rate: number;
  std_total_hr_rate: number;
}

const EditUser = (props: any) => {
  const {
    projectId,
    formData,
    setFormData,
    errors,
    setErrors,
    getUpdatedProjectDetails,
    freezeFields,
  } = props;
  const [currency, setCurrency] = useState();
  const [constantFormData, setConstantFormData] = useState(formData);

  const classes = useStyles();
  const [streams, setStreams] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const [constantCheckedItems, setConstantCheckedItems] =
    useState<CheckedItems>({});
  const [dropdowns, setDropdowns] = useState<Record<string, DropdownOption[]>>(
    {}
  );
  const [projectSumDetails, setProjectSumDetails] = useState<
    ProjectSumDetail[]
  >([]);
  const [constantStreamFields, setConstantStreamFields] =
    useState<StreamFields>({});
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectDetails, setProjectDetails] = useState<ProjectDetail[]>([]);
  const [open, setOpen] = React.useState(false);
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [streamFields, setStreamFields] = useState<StreamFields>({});
  const [shifts, setShifts] = useState<any[]>([]);
  const [formErrors, setFormErrors] = useState<{
    currency: string;
    check: string;
    [key: string]: string; // Index signature for dynamic keys
  }>({
    currency: "",
    check: "",
  });
  const getSingleProject = async (id: any) => {
    try {
      const response = await Get(networkUrls.getSingleProject + `${id}`, true);
      if (response?.data?.api_status === 200) {
        const projectData = response?.data?.data?.projectsFromDB;
        setProjectDetails([projectData]);
        setProjectSumDetails([response?.data?.data.sums]);
        setCurrency(
          response?.data?.data?.projectsFromDB?.currencyDetails?.region_value
        );

        setFormData({
          opportunityName: projectData.project_name,
          region: projectData.regionDetails.name,
          opportunityType: projectData.project_type,
        });
      }
    } catch (error) {
      console.log("Error getting Project Details", error);
    }
  };

  useEffect(() => {
    if (projectId !== undefined) {
      getAllShiftConfig();
      getSingleProject(projectId);
      getAllDropDowns();
    }
  }, [projectId]);


  const validateForm = () => {
    let isValid = true;
    const newerrors: any = {};

    const formerror: any = {};

    if (!formData.opportunityName.trim()) {
      newerrors.opportunityName = "Project name is required";
      formerror.opportunityName = "Project name is required";
      isValid = false;
    }

    if (!formData.region) {
      newerrors.region = "Region is required";
      formerror.region = "Region is required";
      isValid = false;
    }

    if (!formData.opportunityType) {
      newerrors.opportunityType = "Project type is required";
      formerror.opportunityType = "Project type is required";
      isValid = false;
    }

    if (!Object.values(checkedItems).includes(true)) {
      newerrors.check = "Please select at least one stream";
      isValid = false;
    }

    if (!currency) {
      newerrors.currency = "Currency is required";
      isValid = false;
    }

    for (const stream in streamFields) {
      if (streamFields.hasOwnProperty(stream)) {
        // eslint-disable-next-line no-loop-func
        streamFields[stream].forEach((field, index) => {
          if (!field.level) {
            newerrors[`level_${stream}_${index}`] = "Level is required";
            isValid = false;
          }
          if (!field.shift) {
            newerrors[`shift_${stream}_${index}`] = "Shift is required";
            isValid = false;
          }
          if (!field.no_of_resources) {
            newerrors[`no_of_resources_${stream}_${index}`] =
              "Number of resources is required";
            isValid = false;
          }
          if (!field.total_budgeted_hours) {
            newerrors[`total_budgeted_hours_${stream}_${index}`] =
              "Total budget hours is required";
            isValid = false;
          }
        });
      }
    }

    setFormErrors(newerrors);
    setErrors(formerror);

    return isValid;
  };
  const sumFields = (obj: StreamFields, field: keyof UpdatedField): number => {
    const total = Object.values(obj)
      .flat()
      .reduce((acc: number, item: any) => acc + Number(item[field]), 0);
    return total;
  };

  const getAllDropDowns = async () => {
    try {
      const response = await Get(networkUrls.getDropdownValues, true);
      if (response?.data?.api_status === 200) {
        setDropdowns(response?.data?.data);
        setStreams(response?.data?.data?.stream);
      }
    } catch (error) {
      console.log("Error getting Dropdown values", error);
    }
  };

  const getAllShiftConfig = async () => {
    try {
      const response = await Get(networkUrls.getAllShifts, true);
      if (response?.data?.api_status === 200) {
        setShifts(response?.data?.data);
      }
    } catch (error) {
      console.log("Error getting shift config values", error);
    }
  };

  const handleDigitChange = (
    streamName: string,
    index: number,
    fieldName: string,
    value: string
  ) => {
    if (/^[0-9]*$/.test(value)) {
      handleFieldChange(streamName, index, fieldName, value);
    }
  };

  const handleChange = (event: any) => {
    const { name, checked, value } = event.target;
    setCurrency(value);
    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      check: Object.values({ ...checkedItems, [name]: checked }).includes(true)
        ? ""
        : "Please select at least one stream",
    }));
    if (checked) {
      setStreamFields((prevState) => {
        const newFields = {
          level: "",
          no_of_resources: "",
          total_budgeted_hours: "",
          shift: "",
          onsite: "",
        };
        return {
          ...prevState,
          [name]: [...(prevState[name] || []), newFields],
        };
      });
    } else {
      const obj = Object.fromEntries(
        Object.entries(streamFields).filter(([key]) => key !== name)
      );
      setStreamFields(obj);
    }
  };

  const handleDeleteFields = (key: string, index: number) => {
    const updatedArray = [...streamFields[key]];

    updatedArray.splice(index, 1);

    setStreamFields({
      ...streamFields,
      [key]: updatedArray,
    });
  };

  const handleAddLevel = (streamName: string) => {
    setStreamFields((prevState: any) => {
      const newFields = {
        level: "",
        no_of_resources: "",
        total_budgeted_hours: "",
        shift: "",
        onsite: "",
      };
      return {
        ...prevState,
        [streamName]: [...(prevState[streamName] || []), newFields],
      };
    });
  };

  function checkUniqueNames(updatedFields: any) {
    const keys = Object.keys(updatedFields);
    
    for (const key of keys) {
        const keySet = new Set(); 
        for (const item of updatedFields[key]) {
            const { level, onsite, shift } = item;
            const keyString = `${level}_${onsite?.length !==0 || onsite === true ? onsite : false}_${shift}`; // Create a unique string key
            if (keySet.has(keyString)) {
                return false; 
            }
            keySet.add(keyString); 
        }
    }
    
    return true; 
}

  const handleFieldChange = (
    streamName: string,
    index: number,
    fieldName: string,
    value: any
  ) => {
    if ((fieldName === "level" || fieldName === "onsite" || fieldName === "shift") && streamFields[streamName][0].level && streamFields[streamName][0].shift) {
      const updatedFields = [...(streamFields[streamName] || [])];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldName]: value,
      };
      const newStream = {
        ...streamFields,
        [streamName]: updatedFields,
      };
      const response = checkUniqueNames(newStream);

      if (!response) {
        setIsShowSnackbar(true);
        setSnackbarData((prevState: any) => ({
          ...prevState,
          color: "error",
          message: `In ${streamName} level ${newStream[streamName][index].level} is already exists`,
        }));
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
    }
    setStreamFields((prevState) => {
      const updatedFields = [...(prevState[streamName] || [])];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldName]:
          fieldName === "no_of_resources" ||
          fieldName === "total_budgeted_hours"
            ? Number(value)
            : value,
      };
      return {
        ...prevState,
        [streamName]: updatedFields,
      };
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [`${fieldName}_${streamName}_${index}`]: "",
    }));
  };


  useEffect(() => {
    if (isUpdate) {
      updateEditProject();
    }
  }, [isUpdate]);

  const updateEditProject = async () => {
    freezeFields(true);
    setIsDisabled(true);
    const result: any = [];
    const regionId = dropdowns?.region.filter(
      (item: any) => item.name === formData.region
    );
    // const currencyId = dropdowns?.region?.filter(
    //   (arr: any) => arr.region_value === currency
    // );
    for (const stream in streamFields) {
      if (streamFields.hasOwnProperty(stream)) {
        streamFields[stream].forEach((item: any) => {
          result.push({
            level: item.level,
            stream: stream,
            shift_id: getShiftId(item.shift),
            shift_price: getShiftPrice(item.shift),
            onsite: item.onsite === "" ? false : item.onsite,
            total_budget_hours: Number(item.total_budgeted_hours),
            no_of_resources: Number(item.no_of_resources),
          });
        });
      }
    }

    const payload = {
      project_type: formData.opportunityType,
      region: regionId[0]?.id,
      project_name: formData.opportunityName,
      total_hrs: sumFields(streamFields, "total_budgeted_hours"),
      project_config: result,
    };

    try {
      const response: any = await Put(
        networkUrls.editProject + `${projectId}`,
        payload,
        true
      );
      if (response?.response?.api_status === 200) {
        setIsDisabled(false);
        getSingleProject(projectId);
        setIsShowSnackbar(true);
        setSnackbarData((prevState) => ({
          ...prevState,
          color: "success",
          message: response?.response?.message,
        }));
        getUpdatedProjectDetails(projectId);
      } else {
        setIsShowSnackbar(true);
        setIsDisabled(false);
        freezeFields(false);
        setSnackbarData((prevState) => ({
          ...prevState,
          color: "error",
          message: response?.response?.message,
        }));
      }
    } catch (error) {
      console.log(error, "Error Editing Projects");
      freezeFields(false);
    }

    setIsUpdate(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validateForm()) return;
    const validate = compareMultiplePairs(
      [streamFields, constantStreamFields],
      [checkedItems, constantCheckedItems],
      [formData, constantFormData]
    );
    if (!validate) {
      setIsShowSnackbar(true);
      setSnackbarData({
        color: "error",
        message: "Please make changes",
      });
      return;
    }
    setOpen(true);
  };

  const getShiftId = (shiftName: string) => {
    const shift = shifts?.filter(
      (arr: any, index: any) => arr.shift_value.name === shiftName
    );
    const shiftId = shift[0]?.shift_id;
    return shiftId;
  };

  const getShiftPrice = (shiftName: string) => {
    const shift = shifts?.filter(
      (arr: any, index: any) => arr.shift_value.name === shiftName
    );
    const shiftId = shift[0]?.shift_price;
    return shiftId;
  };
  useEffect(() => {
    // getAllProjects();
    getAllDropDowns();
  }, []);

  useEffect(() => {
    if (projectDetails.length > 0 && shifts.length > 0) {
      const projectData = projectDetails[0];

      // Initialize checked items based on project items
      const streamsChecked: CheckedItems = {};
      projectData.projectItems?.forEach((item) => {
        streamsChecked[item.projectStreams.name] = true;
      });
      setCheckedItems(streamsChecked);
      setConstantCheckedItems(streamsChecked);
      const newStreamFields: StreamFields = {};
      projectData.projectItems?.forEach((item) => {
        const streamName: any = item.projectStreams.name;
        const newFields: any = {
          level: item.projectLevels.name,
          no_of_resources: item.no_of_resources,
          total_budgeted_hours: item.total_budgeted_hours,
          onsite: item.onsite,
          shift: shifts?.find((arr: any) => arr.shift_id === item.shift_id)
            ?.shift_value?.name,
          // getShiftName(item.shift_id)
        };
        if (!newStreamFields[streamName]) {
          newStreamFields[streamName] = [newFields];
        } else {
          newStreamFields[streamName].push(newFields);
        }
      });
      setStreamFields(newStreamFields);
      setConstantStreamFields(newStreamFields);
    }
  }, [projectDetails, shifts]);


 
  return (
    <>
      {projectDetails?.length > 0 ? (
        <Grid>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#1B305D",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Edit opportunity
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #1670C3",
                      padding: "4px 10px",
                    }}
                  >
                    <Typography style={{ color: "#757575" }}>
                      Currency &nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    {dropdowns?.region && dropdowns.region.length > 0 && (
                      <Select
                        value={formData.region}
                        variant="standard"
                        disableUnderline
                        name="currency"
                        style={{ color: "#1B305D", fontWeight: "700" }}
                        onChange={handleChange}
                        disabled={true}
                      >
                        {dropdowns.region.map((option: any) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.region_value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ flexGrow: 1, color: "#ABAAAA" }}>
                  Required streams
                </Typography>

                <Grid display="flex" className={classes.root} sx={{ gap: 5 }}>
                  {streams.length > 0 &&
                    streams.map((item: any, index: any) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            name={item.name}
                            checked={checkedItems[item.name] || false}
                            onChange={handleChange}
                            disabled={isDisabled}
                          />
                        }
                        label={item.name}
                      />
                    ))}
                </Grid>
                {formErrors.check && (
                  <FormHelperText error>{formErrors.check}</FormHelperText>
                )}
              </Grid>
              {Object.entries(checkedItems).map(
                ([key, isChecked]) =>
                  isChecked && (
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        backgroundColor: "#F2F3F5",
                        padding: "20px",
                        marginBottom: "20px",
                        marginTop: "10px",
                        paddingLeft: "25px",
                      }}
                      key={key}
                    >
                      <Grid item xs={12}>
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "24px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              color: "#1B305D",
                            }}
                          >
                            {key}
                          </Typography>
                          <Grid>
                            <Button
                              endIcon={
                                <AddIcon
                                  sx={{
                                    backgroundColor: "#1670C3",
                                    height: 22,
                                    width: 22,
                                    color: "white",
                                    borderRadius: "11px",
                                    fontSize: "16px",
                                    fontFamily: "Helvetica Neue",
                                  }}
                                />
                              }
                              sx={{
                                color: "#1B305D",
                                fontWeight: 500,
                                textTransform: "capitalize",
                              }}
                              onClick={() => handleAddLevel(key)}
                            >
                              Add level
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      {streamFields &&
                        streamFields[key] &&
                        shifts &&
                        shifts?.length > 0 &&
                        streamFields[key]?.map((field, index) => {
                         
                          return (
                            <React.Fragment key={index}>
                              <Grid
                                container
                                sx={{ marginTop: "15px", paddingLeft: "10px" }}
                                spacing={1}
                              >
                                <Grid item xs={12} sm={6} md={2.5}>
                                  <TextField
                                    select
                                    label="Level"
                                    disabled={isDisabled}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="level"
                                    value={field?.level}
                                    sx={dropdowniconstyles}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        key,
                                        index,
                                        "level",
                                        e.target.value
                                      )
                                    }
                                    error={
                                      !!formErrors[`level_${key}_${index}`]
                                    }
                                    helperText={
                                      formErrors[`level_${key}_${index}`]
                                        ? formErrors[`level_${key}_${index}`]
                                        : ""
                                    }
                                  >
                                    {dropdowns?.level?.map((option: any) => (
                                      <MenuItem
                                        key={option}
                                        value={option.name}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2.5}>
                                  <TextField
                                    select
                                    label="Shift"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="shift"
                                    value={field?.shift}
                                    sx={dropdowniconstyles}
                                    disabled={isDisabled}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        key,
                                        index,
                                        "shift",
                                        e.target.value
                                      )
                                    }
                                    error={
                                      !!formErrors[`shift_${key}_${index}`]
                                    }
                                    helperText={
                                      formErrors[`shift_${key}_${index}`]
                                        ? formErrors[`shift_${key}_${index}`]
                                        : ""
                                    }
                                  >
                                    {shifts.map((option: any) => (
                                      <MenuItem
                                        key={option.shift_id}
                                        value={option.shift_value.name}
                                      >
                                        {option.shift_value.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2.5}>
                                  <TextField
                                    label="Number of resources"
                                    autoComplete="off"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="no_of_resources"
                                    value={
                                      field.no_of_resources === 0
                                        ? ""
                                        : field.no_of_resources
                                    }
                                    disabled={isDisabled}
                                    onChange={(e) =>
                                      handleDigitChange(
                                        key,
                                        index,
                                        "no_of_resources",
                                        e.target.value
                                      )
                                    }
                                    error={
                                      !!formErrors[
                                        `no_of_resources_${key}_${index}`
                                      ]
                                    }
                                    helperText={
                                      formErrors[
                                        `no_of_resources_${key}_${index}`
                                      ]
                                        ? formErrors[
                                            `no_of_resources_${key}_${index}`
                                          ]
                                        : ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2.5}>
                                  <TextField
                                    label="Total budgeted hours"
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    size="small"
                                    name="total_budgeted_hours"
                                    value={field.total_budgeted_hours}
                                    disabled={isDisabled}
                                    onChange={(e) =>
                                      handleDigitChange(
                                        key,
                                        index,
                                        "total_budgeted_hours",
                                        e.target.value
                                      )
                                    }
                                    error={
                                      !!formErrors[
                                        `total_budgeted_hours_${key}_${index}`
                                      ]
                                    }
                                    helperText={
                                      formErrors[
                                        `total_budgeted_hours_${key}_${index}`
                                      ]
                                        ? formErrors[
                                            `total_budgeted_hours_${key}_${index}`
                                          ]
                                        : ""
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={1.3}
                                  className={classes.root}
                                >
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        name="onsite"
                                        checked={field.onsite || false}
                                        onChange={(e) =>
                                          handleFieldChange(
                                            key,
                                            index,
                                            "onsite",
                                            e.target.checked
                                          )
                                        }
                                        disabled={isDisabled}
                                      />
                                    }
                                    sx={{
                                      color: "#1B305D",
                                      fontFamily: "Helvetica Neue",
                                    }}
                                    label="Onsite"
                                  />
                                </Grid>
                                {index !== 0 && (
                                  <Grid item xs={12} sm={6} md={0.5}>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteFields(key, index)
                                      }
                                    >
                                      <img
                                        src={DeleteIcon}
                                        alt="delete_icon"
                                        height="25px"
                                        width="25px"
                                        style={{ marginRight: "8px" }}
                                      />
                                    </IconButton>
                                  </Grid>
                                )}
                              </Grid>
                            </React.Fragment>
                          );
                        })}
                    </Grid>
                  )
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                paddingLeft: "10px",
              }}
            >
              <Grid>
                <Typography sx={{ color: "#757575" }}>
                  Total number of resources
                </Typography>
                <Typography
                  sx={{
                    color: "#1B305D",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  {sumFields(streamFields, "no_of_resources")}
                </Typography>
              </Grid>
              <Grid>
                <Typography sx={{ color: "#757575" }}>
                  Total budgeted hours
                </Typography>
                <Typography
                  sx={{
                    color: "#1B305D",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  {sumFields(streamFields, "total_budgeted_hours")}
                </Typography>
              </Grid>

              <Grid>
                <Box position="relative" display="inline-flex" width="100%">
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit-button"
                    disabled={isDisabled}
                  >
                    Update
                  </Button>
                  {isDisabled && (
                    <CircularProgress
                      size="sm"
                      className="buttonProgress"
                      style={{
                        color: "primary",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress variant="solid" />
          </Box>
        </>
      )}
      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}
      {open && (
        <ReuseableModal
          open={open}
          setOpen={setOpen}
          type="update"
          setIsUpdate={setIsUpdate}
        />
      )}
    </>
  );
};

export default EditUser;
