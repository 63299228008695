import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "../../assests/images/Delete.png";
import { Get, Post } from "../../services/apiServices";
import { networkUrls } from "../../services/networkUrls";
import { makeStyles } from "@mui/styles";
import "./ProjectView/View.css";
import { CircularProgress } from "@mui/joy";

const useStyles = makeStyles({
  root: {
    "& .MuiCheckbox-root .MuiSvgIcon-root": {
      color: "#1670C3",
      borderRadius: "1px",
    },
    "& .Mui-checked .MuiSvgIcon-root": {},
  },
});

const dropdowniconstyles = {
  ".MuiSelect-icon": {
    color: "#1670C3",
  },
};
interface DropdownOption {
  region_value: any;
  id?: number;
  name: string;
  region?: string;
}

interface CheckedItems {
  [key: string]: boolean;
}

interface AdditionalFields {
  level: string;
  no_of_resources: number;
  total_budget_hours: number;
}

interface StreamFields {
  [key: string]: AdditionalFields[];
}
const Create = ({
  getAllProjects,
  setIsShowSnackbar,
  setSnackbarData,
  setNavigationId,
  setIsNavigate,
  setSelectedItemId,
  setFormErrors,
  formErrors,
  checkedItems,
  setCheckedItems,
  streamFields,
  setStreamFields,
  formData,
  setFormData,
}: any) => {
  const classes = useStyles();

  const [streams, setStreams] = useState([]);
  const [dropdowns, setDropdowns] = useState<Record<string, DropdownOption[]>>(
    {}
  );
  const[shifts,setShifts]=useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currency, setCurrency] = useState();

  
  const validateForm = () => {
    let isValid = true;

    const errors: any = {};

    if (!formData.opportunityName.trim()) {
      errors.opportunityName = "Opportunity name  is required";
      isValid = false;
    }

    if (formData.region === "none") {
      errors.region = "Region is required";
      isValid = false;
    }

    if (!currency) {
      errors.currency = "Currency is required";
      isValid = false;
    }
    if (formData.opportunityType === "none") {
      errors.opportunityType = "Project type is required";
      isValid = false;
    }

    if (!Object.values(checkedItems).includes(true)) {
      errors.check = "Please select at least one stream";
      isValid = false;
    }

    for (const stream in streamFields) {
      if (streamFields.hasOwnProperty(stream)) {
        // eslint-disable-next-line no-loop-func
        streamFields[stream].forEach((field: any, index: any) => {
          if (!field.level) {
            errors[`level_${stream}_${index}`] = "Level is required";
            isValid = false;
          }
          if (!field.shift) {
            errors[`shift_${stream}_${index}`] = "Shift is required";
            isValid = false;
          }
          if (!field.no_of_resources) {
            errors[`no_of_resources_${stream}_${index}`] =
              "Number of resources is required";
            isValid = false;
          }
          if (!field.total_budget_hours) {
            errors[`total_budget_hours_${stream}_${index}`] =
              "Total budget hours is required";
            isValid = false;
          }
        });
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const sumFields = (
    obj: StreamFields,
    field: keyof AdditionalFields
  ): number => {
    return Object.values(obj)
      .flat()
      .reduce((acc: number, item: any) => acc + Number(item[field]), 0);
  };

  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "region") setCurrency(value);
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const getAllDropDowns = async () => {
    try {
      const response = await Get(networkUrls.getDropdownValues, true);
      if (response?.data?.api_status === 200) {
        setDropdowns(response?.data?.data);
        setStreams(response?.data?.data?.stream);
      }
    } catch (error) {
      console.log("Error getting Dropdown values", error);
    }
  };
  const getAllShiftConfig=async()=>{
    try {
      const response = await Get(networkUrls.getAllShifts, true);
      if (response?.data?.api_status === 200) {
        const shift=response?.data?.data;
        const filterShifts=shift.filter((arr:any)=>arr?.shift_value?.name!=="Regular")
        setShifts(response?.data?.data);
        
      }
    } catch (error) {
      console.log("Error getting shift config values", error);
    }
  }

  const handleChange = (event: any) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      check: Object.values({ ...checkedItems, [name]: checked }).includes(true)
        ? ""
        : "Please select at least one stream",
    }));
    if (checked) {
      setStreamFields((prevState: any) => {
        const newFields = {
          level: "",
          no_of_resources: "",
          total_budget_hours: "",
          shift:"",
          onsite:""
        };
        return {
          ...prevState,
          [name]: [...(prevState[name] || []), newFields],
        };
      });
    } else {
      const obj = Object.fromEntries(
        Object.entries(streamFields).filter(([key]) => key !== name)
      );
      setStreamFields(obj);
    }
  };

  const handleDeleteFields = (key: string, index: number) => {
    const updatedArray = [...streamFields[key]];

    updatedArray.splice(index, 1);

    setStreamFields({
      ...streamFields,
      [key]: updatedArray,
    });
  };

  function checkUniqueNames(updatedFields: any) {
    const keys = Object.keys(updatedFields);
    
    for (const key of keys) {
        const keySet = new Set(); 
        for (const item of updatedFields[key]) {
            const { level, onsite, shift } = item;
            const keyString = `${level.name}_${onsite?.length !==0 || onsite === true ? onsite : false}_${shift.name}`; // Create a unique string key
            if (keySet.has(keyString)) {
                return false; 
            }
            keySet.add(keyString); 
        }
    }
    
    return true; 
}

  const handleAddLevel = (streamName: string) => {
    setStreamFields((prevState: any) => {
      const newFields = {
        level: "",
        no_of_resources: "",
        total_budget_hours: "",
        shift: "",
        onsite:""
      };
      return {
        ...prevState,
        [streamName]: [...(prevState[streamName] || []), newFields],
      };
    });
  };

  const handleDigitChange = (
    streamName: string,
    index: number,
    fieldName: string,
    value: string
  ) => {
    if (/^[0-9]*$/.test(value)) {
      handleFieldChange(streamName, index, fieldName, value);
    }
  };

  const handleFieldChange = (
    streamName: string,
    index: number,
    fieldName: string,
    value: any
  ) => {
    if ((fieldName === "level" || fieldName === "onsite" || fieldName === "shift") &&  streamFields[streamName][0].level?.name && streamFields[streamName][0].shift?.name) {
      const updatedFields = [...(streamFields[streamName] || [])];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldName]: value,
      };
      const newStream = {
        ...streamFields,
        [streamName]: updatedFields,
      };
      const response = checkUniqueNames(newStream);
      
      if (!response) {
        setIsShowSnackbar(true);
        setSnackbarData((prevState: any) => ({
          ...prevState,
          color: "error",
          message: `In ${streamName} level ${newStream[streamName][index]?.level?.name} is already exists`,
        }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }
    setStreamFields((prevState: any) => {
      const updatedFields = [...(prevState[streamName] || [])];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldName]: value,
      };
      return {
        ...prevState,
        [streamName]: updatedFields,
      };
    });
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [`${fieldName}_${streamName}_${index}`]: "",
    }));
  };

 

  const getShiftPrice=(id:number)=>{
    const shift= shifts?.filter((arr:any,index:any)=>arr.shift_id===id);
    const shiftId=shift[0]?.shift_price
    return shiftId
   
  }
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validateForm()) return;
    setIsDisabled(true);
    const result: any = [];
    const currencyId = dropdowns?.region?.filter(
      (arr: any) => arr.region_value === currency
    );
    const regionId = dropdowns?.region.filter(
      (item: any) => item.region_value === formData.region
    );
    for (const stream in streamFields) {
      if (streamFields.hasOwnProperty(stream)) {
        streamFields[stream].forEach((item: any) => {
          result.push({
            level: item.level.name,
            stream: stream,
            shift_id:item.shift.id,
            shift_price:getShiftPrice(item.shift.id),
            onsite:item.onsite==="" ? false : item.onsite,
            total_budget_hours: Number(item.total_budget_hours),
            no_of_resources: Number(item.no_of_resources),
          });
        });
      }
    }
    const payload = {
      project_type: formData.opportunityType,
      region: regionId[0]?.id,
      project_name: formData.opportunityName,
      project_config: result,
    };

    try {
      const response = await Post(networkUrls.createProject, payload, true);
      
      if (response?.data?.api_status === 200) {
        getAllProjects();
        setIsDisabled(false);
        setIsShowSnackbar(true);
        setIsNavigate(true);
        setNavigationId(response?.data?.data?.id); // projectid neeed to set
        setSelectedItemId(response?.data?.data?.id);
        setSnackbarData((prevState: any) => ({
          ...prevState,
          color: "success",
          message: "Oppportunity Created Successfully",
        }));
        setFormData({
          opportunityName: "",
          opportunityType: "",
          region: "",
        });

        setCheckedItems({});
        setStreamFields({});
      } else {
        setIsShowSnackbar(true);
        setIsDisabled(false);
        setSnackbarData((prevState: any) => ({
          ...prevState,
          color: "error",
          message: response?.data?.message,
        }));
      }
    } catch (error) {
      console.log(error, "Error Creating Projects");
    }
  };

  useEffect(() => {
    getAllDropDowns();
    getAllShiftConfig()
  }, []);


  return (
    <>
      <Grid sx={{ paddingLeft: "10px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#1B305D",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  New opportunity
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "20px",
                    border: "1px solid #1670C3",
                    padding: "4px 10px",
                  }}
                >
                  <Typography style={{ color: "#757575" }}>
                    Currency &nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>

                  {dropdowns?.region && dropdowns.region.length > 0 && (
                    <>
                      <Select
                        value={formData.region}
                        variant="standard"
                        disableUnderline
                        name="currency"
                        style={{ color: "#1B305D", fontWeight: "700",top:"2px" }}
                        disabled={true}
                      >
                        {dropdowns?.region?.map((option: any) => (
                          <MenuItem key={option.id} value={option.region_value}>
                            {option.region_value}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3.8}>
              <Typography
                sx={{ flexGrow: 1, color: "#ABAAAA", paddingBottom: "4.5px" }}
              >
                Opportunity name
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="off"
                size="small"
                name="opportunityName"
                sx={{ color: "#ABAAAA" }}
                placeholder="Opportunity name"
                onChange={handleFormChange}
                value={formData?.opportunityName}
                error={!!formErrors.opportunityName}
                helperText={formErrors.opportunityName}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3.8}>
              <Typography
                sx={{ flexGrow: 1, color: "#ABAAAA", paddingBottom: "4.5px" }}
              >
                Region
              </Typography>
              <TextField
                select
                fullWidth
                variant="outlined"
                size="small"
                name="region"
                // label="Select Region"
                onChange={handleFormChange}
                value={formData.region}
                error={!!formErrors.region}
                helperText={formErrors.region}
                sx={dropdowniconstyles}
                disabled={isDisabled}
              >
                <MenuItem value="none" disabled>
                  Select region
                </MenuItem>
                {dropdowns?.region?.map((option: any) => (
                  <MenuItem key={option.id} value={option.region_value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3.8}>
              <Typography
                sx={{ flexGrow: 1, color: "#ABAAAA", paddingBottom: "4.5px" }}
              >
                Opportunity type
              </Typography>
              <TextField
                select
                // label="Select Opportunity Type"
                placeholder="Project Type"
                fullWidth
                variant="outlined"
                size="small"
                name="opportunityType"
                value={formData.opportunityType}
                onChange={handleFormChange}
                error={!!formErrors.opportunityType}
                helperText={formErrors.opportunityType}
                sx={dropdowniconstyles}
                disabled={isDisabled}
              >
                <MenuItem value="none" disabled>
                  Select opportunity type
                </MenuItem>
                {dropdowns?.projectType?.map((option: any) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ flexGrow: 1, color: "#ABAAAA" }}>
                Required streams
              </Typography>

              <Grid display="flex" className={classes.root} sx={{ gap: 5 }}>
                {streams.length > 0 &&
                  streams.map((item: any, index: any) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          name={item.name}
                          checked={checkedItems[item.name] || false}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                      }
                      sx={{ color: "#1B305D", fontFamily: "Helvetica Neue" }}
                      label={item.name}
                    />
                  ))}
              </Grid>
              {formErrors.check && (
                <FormHelperText error>{formErrors.check}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              {Object.entries(checkedItems).map(
                ([key, isChecked]): any =>
                  isChecked && (
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        backgroundColor: "#F2F3F5",
                        padding: "20px 5px 20px 5px",
                        marginBottom: "20px",
                        marginTop: "10px",
                      }}
                      key={key}
                    >
                      <Grid item xs={12}>
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "24px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              color: "#1B305D",
                            }}
                          >
                            {key}
                          </Typography>
                          <Grid>
                            <Button
                              endIcon={
                                <AddIcon
                                  sx={{
                                    backgroundColor: "#1670C3",
                                    height: 22,
                                    width: 22,
                                    color: "white",
                                    borderRadius: "11px",
                                    fontSize: "16px",
                                    fontFamily: "Helvetica Neue",
                                  }}
                                />
                              }
                              sx={{
                                color: "#1B305D",
                                fontWeight: 500,
                                textTransform: "capitalize",
                              }}
                              onClick={() => handleAddLevel(key)}
                              disabled={isDisabled}
                            >
                              Add level
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      {streamFields[key]?.map((field: any, index: any) => (
                        <React.Fragment key={index}>
                           <Grid container sx={{marginTop:"15px",paddingLeft:"10px"}} spacing={1}>
                          <Grid item xs={12} sm={6} md={2.5}>
                            <TextField
                              select
                              label="Level"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="level"
                              value={field.level}
                              sx={dropdowniconstyles}
                              disabled={isDisabled}
                              onChange={(e) =>
                                handleFieldChange(
                                  key,
                                  index,
                                  "level",
                                  e.target.value
                                )
                              }
                              error={!!formErrors[`level_${key}_${index}`]}
                              helperText={
                                formErrors[`level_${key}_${index}`]
                                  ? formErrors[`level_${key}_${index}`]
                                  : ""
                              }
                            >
                              {dropdowns?.level.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2.5}>
                            <TextField
                              select
                              label="Shift"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="shift"
                              value={field.shift}
                              sx={dropdowniconstyles}
                              disabled={isDisabled}
                              onChange={(e) =>
                                handleFieldChange(
                                  key,
                                  index,
                                  "shift",
                                  e.target.value
                                )
                              }
                              error={!!formErrors[`shift_${key}_${index}`]}
                              helperText={
                                formErrors[`shift_${key}_${index}`]
                                  ? formErrors[`shift_${key}_${index}`]
                                  : ""
                              }
                            >
                              {dropdowns?.shifts.map((option: any) => (
                                <MenuItem key={option.shift_id} value={option}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2.5}>
                            <TextField
                              label="Number of resources"
                              autoComplete="off"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="no_of_resources"
                              value={field.no_of_resources}
                              disabled={isDisabled}
                              inputProps={{
                                maxLength: 6,
                              }}
                              onChange={(e) =>
                                handleDigitChange(
                                  key,
                                  index,
                                  "no_of_resources",
                                  e.target.value
                                )
                              }
                              error={
                                !!formErrors[`no_of_resources_${key}_${index}`]
                              }
                              helperText={
                                formErrors[`no_of_resources_${key}_${index}`]
                                  ? formErrors[
                                      `no_of_resources_${key}_${index}`
                                    ]
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={2.5}>
                            <TextField
                              label="Total budgeted hours"
                              fullWidth
                              variant="outlined"
                              autoComplete="off"
                              size="small"
                              name="total_budget_hours"
                              value={field.total_budget_hours}
                              disabled={isDisabled}
                              inputProps={{
                                maxLength: 6,
                              }}
                              onChange={(e) =>
                                handleDigitChange(
                                  key,
                                  index,
                                  "total_budget_hours",
                                  e.target.value
                                )
                              }
                              error={
                                !!formErrors[
                                  `total_budget_hours_${key}_${index}`
                                ]
                              }
                              helperText={
                                formErrors[`total_budget_hours_${key}_${index}`]
                                  ? formErrors[
                                      `total_budget_hours_${key}_${index}`
                                    ]
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={1.3}  className={classes.root}>
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  name={field.onsite}
                                  checked={field.onsite || false}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      key,
                                      index,
                                      "onsite",
                                      e.target.checked
                                    )
                                  }
                                  disabled={isDisabled}
                                />
                              }
                              sx={{
                                color: "#1B305D",
                                fontFamily: "Helvetica Neue",
                              }}
                              label="Onsite"
                            />
                          </Grid>
                          {index !== 0 && (
                            <Grid item xs={12} sm={6} md={0.5}>
                              <IconButton
                                onClick={() => handleDeleteFields(key, index)}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="delete_icon"
                                  height="25px"
                                  width="25px"
                                  style={{ marginRight: "8px" }}
                                />
                              </IconButton>
                            </Grid>
                          )}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Grid>
              <Typography sx={{ color: "#757575" }}>
                Total number of resources
              </Typography>
              <Typography
                sx={{
                  color: "#1B305D",
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                {sumFields(streamFields, "no_of_resources")}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ color: "#757575" }}>
                Total budgeted hours
              </Typography>
              <Typography
                sx={{
                  color: "#1B305D",
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                {sumFields(streamFields, "total_budget_hours")}
              </Typography>
            </Grid>

            <Grid>
              <Box position="relative" display="inline-flex" width="100%">
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-button"
                  disabled={isDisabled}
                >
                  Create
                </Button>
                {isDisabled && (
                  <CircularProgress
                    size="sm"
                    className="buttonProgress"
                    style={{
                      color: "primary",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default Create;
