import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import myImage from "../../assests/images/Group 2066.png";
import { makeStyles } from "@mui/styles";
import { networkUrls } from "../../../services/networkUrls";
import { Get } from "../../../services/apiServices";
import ReuseableSnackbar from "../../..//components/common/Snackbar/ResueableSnackbar";
import "./AdminDashboard.css";
import ProjectEstimator from "../PricingEstimator/PricingEstimator";
import { FileUploadComponent } from "../FileUpload/FileUpload";
import LogoutIcon from "@mui/icons-material/Logout";
import ReuseableModal from "../../../components/common/Modal/Modal";
import ShiftConfiguration from "../ShiftConfiguration/ShiftConfiguration";
import Cookies from "js-cookie";
import { Cookie } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "& .MuiCheckbox-root .MuiSvgIcon-root": {
      color: "#1670C3",
      borderRadius: "1px",
    },
    "& .Mui-checked .MuiSvgIcon-root": {},
  },
});

interface DropdownOption {
  id?: number;
  name: string;
  region?: string;
}

interface CheckedItems {
  [key: string]: boolean;
}

interface AdditionalFields {
  level: string;
  no_of_resources: number;
  total_budget_hours: number;
}

interface StreamFields {
  [key: string]: AdditionalFields[];
}

const AdminDashBoard = () => {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [superAdmin, setSuperAdmin] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  // const [adminRole, setAdminRole] = useState("")

  const [isView, setIsView] = useState(false);

  useEffect(() => {
    let superAdmin: any = Cookies.get("super_admin");
    let role: any = Cookies.get("user_role");
    setUserRole(role);
    setSuperAdmin(superAdmin);
  }, []);

  const handleLogout = () => {
    setOpen(true);
  };


  const navigate = useNavigate()
  const MenuItems: any[] = [
    {
      id: 0,
      name: "Configuration",
    },
    {
      id: 1,
      name: "File Upload",
    },
    {
      id: 2,
      name: "Shift Configuration",
    },
  ];

  const isSwitch = (id: number) => {
    setIsView(true);
    setSelectedItemId(id);
  };

  const handleChange = (e: any) => {
    if (e.target.value === "Finance") {
      Cookies.set("user_role", "Sales");
      setUserRole("Sales");
      navigate("/")
    } else {
      Cookies.set("user_role", "Finance");
      setUserRole("Finance");
      navigate("/admin-dashboard")
    }
  };

  return (
    <Grid
      sx={{
        minHeight: !isView ? "100vh" : "150vh",
        backgroundColor: "#EBEFF5",
      }}
    >
      <Grid
        sx={{
          height: "32vh",
          // backgroundColor: "grey",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        className="backgroundMainImage"
      >
        <span
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: "25px",
            marginBottom: "40px",
          }}
        >
          Project Estimation Configuration
        </span>
        {superAdmin === "true"? (
          <MenuItem
            style={{
              width: "192px",
              height: "38px",
              padding: "7px 48px 7px 11px",
              borderRadius: " 4px",
              position: "absolute",
              right: "140px",
              top: "42%",
              transform: "translateY(-50%)",
            }}
          >
            <Typography className="badge" style={{ color: "white" }}>
              Sales
            </Typography>
            <Switch
              checked={userRole === "Finance" || userRole=== "SystemAdmin"}
              style={{color :"white" ,}}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "white", 
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "white", 
                },
                "& .MuiSwitch-switchBase": {
                  color: "gray", 
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "gray",
                },
              }}
              value={userRole}
            />
            <Typography className="badge" style={{ color: "white" }}>
              Finance
            </Typography>
          </MenuItem>
        ) : (
          <></>
        )}
        <Button
          onClick={handleLogout}
          sx={{
            position: "absolute",
            color: "white",
            right: "20px",
            top: "42%",
            transform: "translateY(-50%)",
          }}
        >
          <LogoutIcon />
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          // bottom: "0",
          // left: "50%",
          transform: "translateY(-60px)",
          // height: "78%",
          // margin: "0 20px",
        }}
      >
        <Grid container spacing={0} style={{ paddingBottom: "20px" }}>
          <Grid item xs={0.15}></Grid>
          <Grid
            item
            xs={2.85}
            sx={{
              // width: "35%",
              backgroundColor: "#FCFDFD",
              borderRadius: "10px",
              // marginRight:"14px"
              // overflow:"auto"
              height: "400px",
            }}
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sx={{
                  // maxHeight: 400,
                  height: "400px",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "white",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "grey",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <List>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "700",
                      height: "30px",
                      marginLeft: "31px",
                    }}
                  >
                    Menu
                  </div>
                  {MenuItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          backgroundColor:
                            selectedItemId === item.id
                              ? "#1B305D"
                              : "transparent",
                          "&:hover": {
                            backgroundColor:
                              selectedItemId === item.id
                                ? "#1B305D"
                                : "#1B305D",
                            "& .MuiListItemText-primary": {
                              borderRadius:
                                selectedItemId === 1
                                  ? "10px 10px 0px 0px"
                                  : "0px",
                              color:
                                selectedItemId === item.id ? "#fff" : "#fff",
                            },
                          },
                        }}
                      >
                        <ListItemButton onClick={() => isSwitch(item.id)}>
                          <ListItemText
                            primary={item.name}
                            sx={{
                              color:
                                selectedItemId === item.id ? "#fff" : "#1670C3",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>

                      {index < allProjects.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.15}></Grid>
          <Grid
            item
            xs={8.5}
            sx={{
              minHeight: "100%",
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "#FCFDFD",
              padding: "0px 20px",
              // border: "1px solid #1B305D",
              // marginBottom: "40px",
            }}
          >
            {selectedItemId === 0 ? (
              <ProjectEstimator />
            ) : selectedItemId === 1 ? (
              <FileUploadComponent />
            ) : (
              <ShiftConfiguration />
            )}
          </Grid>
          <Grid item xs={0.1}></Grid>
        </Grid>
      </div>
      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}

      {open && <ReuseableModal open={open} setOpen={setOpen} />}
    </Grid>
  );
};

export default AdminDashBoard;
