import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, IconButton, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/joy/CircularProgress";
import { Get, Post, Put } from "../../../services/apiServices";
import { networkUrls } from "../../../services/networkUrls";
import ReusableButton from "../../../components/common/ReuseableButton/ReusableButton";
import ReuseableSnackbar from "../../../components/common/Snackbar/ResueableSnackbar";
import DeleteIcon from "../../../assests/images/Delete.png";
import { response } from "express";

interface DropdownOption {
  region_value: any;
  id?: number;
  name: string;
  region?: string;
}

interface FormData {
  shiftConfig: any;
}

const shiftname = [
  {
    id: 1,
    name: "Early Morning",
    type: "EARLY_MORNING",
    status: 1,
    created_at: "2024-06-25T11:21:47.000Z",
    updated_at: "2024-06-25T11:21:47.000Z",
  },
  {
    id: 2,
    name: "Afternoon",
    type: "AFTERNOON",
    status: 1,
    created_at: "2024-06-25T11:21:47.000Z",
    updated_at: "2024-06-25T11:21:47.000Z",
  },
  {
    id: 3,
    name: "Night",
    type: "NIGHT",
    status: 1,
    created_at: "2024-06-25T11:21:47.000Z",
    updated_at: "2024-06-25T11:21:47.000Z",
  },
  {
    id: 4,
    name: "Regular",
    type: "REGULAR",
    status: 1,
    created_at: "2024-06-25T11:21:47.000Z",
    updated_at: "2024-06-25T11:21:47.000Z",
  },
];

const ProjectEstimator: React.FC = () => {
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dropdowns, setDropdowns] = useState<Record<string, DropdownOption[]>>(
    {}
  );
  const [shiftdropdown, setShiftdropdown] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [allShifts, setAllShifts] = useState<any[]>([]);
  const [dynamicFields, setDynamicFields] = useState(0);
  const [showFields, setShowFields] = useState<Array<boolean>>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    shiftConfig: [],
  });

  const [errors, setErrors] = useState({
    shiftConfig: [] as any[],
  });

  const handleDigitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: any,
    fieldIndex?: number
  ) => {
    const { value } = event.target;
    if (/^[0-9]*$/.test(value)) {
      handleChange(event, index, fieldIndex);
    }
  };


  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    levelIndex?: number,
    fieldIndex?: number
  ) => {
    const { name, value } = event.target;

    setFormData((prevFormData: any) => {
      const updatedConfig = [...prevFormData.shiftConfig];
      if (levelIndex !== undefined && fieldIndex !== undefined) {
        // Update nested field
        const updatedFields = [...updatedConfig[levelIndex].fields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          [name]: value,
        };
        updatedConfig[levelIndex] = {
          ...updatedConfig[levelIndex],
          fields: updatedFields,
        };
      } else if (levelIndex !== undefined) {
        updatedConfig[levelIndex] = {
          ...updatedConfig[levelIndex],
          [name]: value,
        };
        if (name === "shift_id" && value === "Regular") {
          updatedConfig[levelIndex].shift_price = "0";
          updatedConfig[levelIndex].isDisabled = true;
        } else if (name === "shift_id") {
          updatedConfig[levelIndex].isDisabled = false;
          updatedConfig[levelIndex].shift_price = "";
        }
       
      } else {
        (prevFormData as any)[name] = value;
      }

      return { ...prevFormData, shiftConfig: updatedConfig };
    });

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
      shiftConfig: [
        ...prevErrors.shiftConfig.slice(
          0,
          levelIndex !== undefined ? levelIndex : 0
        ),
        {
          ...prevErrors.shiftConfig[levelIndex !== undefined ? levelIndex : 0],
          [name]: "",
        },
        ...prevErrors.shiftConfig.slice(
          levelIndex !== undefined ? levelIndex + 1 : 1
        ),
      ],
    }));
  };

  const getAllShifts = async (dropdownDaata?: any) => {
    try {
      const response = await Get(networkUrls.getAllShifts,true);
      if (response?.data?.api_status === 200) {
        setAllShifts(response?.data?.data);
        if (response?.data?.data?.length) {
          setIsEditMode(true);
          setFormData((prev: any) => ({
            ...prev,
            shiftConfig: getFormattedRegions(response.data.data),
          }));

          const dropdownLength= dropdownDaata?.shifts?.length
          if (response.data.data.length === dropdownLength) {
            setAddBtnDisabled(true);
          }
          const updatedShowFields: any = Array(response.data.data.length).fill(
            true
          );
          setShowFields([...showFields, ...updatedShowFields]);
        } else {
        }
      }
    } catch (error) {
      console.log(error, "Error getting configurations");
    }
    setIsLoading(false);
  };

  const getAllDropDowns = async () => {
    try {
      const response = await Get(networkUrls.getDropdownValues, true);
      if (response?.data?.api_status === 200) {
        setDropdowns(response?.data?.data);
        const shiftdropdowns = response?.data?.data?.shifts;
        // const filterShiftDropdown = shiftdropdowns.filter(
        //   (arr: any) => arr.name !== "Regular"
        // );
        setShiftdropdown(shiftdropdowns);
        return response?.data?.data;
      }
    } catch (error) {
      console.log("Error getting Dropdown values", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllDropDowns();
        await getAllShifts(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const getFormattedRegionsPayload = (regions: any) => {
    let filteredRegions: any;
    if (allShifts.length) filteredRegions = regions;
    else
      filteredRegions = regions.filter(
        (_: any, index: number) => index !== regions.length - 1
      );
    const updatedRegions = filteredRegions.map((item: any, index2: number) => ({
      shift_id: getShiftId(item.shift_id),
      shift_price: Number(item.shift_price),
    }));
    return updatedRegions;
  };

  const getShiftId = (shiftName: string) => {
    const shift = dropdowns?.shifts?.filter(
      (arr: any, index: any) => arr.name === shiftName
    );
    const shiftId = shift[0]?.id;
    return shiftId;
  };

  function hasDuplicates(array: any) {
    const regions = array.map((item: any) => item.shift_id);
    const uniqueIds = new Set(regions);
    return regions.length !== uniqueIds.size;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateFields()) return;
    setLoader(true);
    const payLoad: any = {
      config: getFormattedRegionsPayload(formData.shiftConfig),
    };

    let response: any;
    if (allShifts.length === 0) {
      response = await Post(networkUrls.createShift, payLoad.config, true);
      if (response.data.api_status === 200) {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "success",
          message: response?.data?.message,
        });
        // getAllConfigurations();
      } else {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "error",
          message: response?.data?.message,
        });
      }
    } else {
      response = await Put(networkUrls.editShift, payLoad.config, true);
      if (response.response.api_status === 200) {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "success",
          message: response?.response?.message,
        });
        getAllShifts();
      } else {
        setIsShowSnackbar(true);
        setSnackbarData({
          color: "error",
          message: response?.response?.message,
        });
      }
    }
    setLoader(false);
  };

  const validateFields = (): boolean => {
    const newErrors: any = {
      shiftConfig: [],
    };

    formData.shiftConfig.forEach((config: any, index: number) => {
      const configErrors = {
        shift_id: "",
        shift_price: "",
      };
      if (index === formData.shiftConfig.length - 1 && allShifts.length === 0) {
        return;
      }

      if (!config.shift_id) {
        configErrors.shift_id = "Shift name is required";
      }
    
      if (config.shift_price==="") {
        configErrors.shift_price = "Amount is required";
      }

      newErrors["shiftConfig"][index] = configErrors;
    });

    setErrors(newErrors);

    const hasErrors =
      Object.values(newErrors).some(
        (fieldErrors) => fieldErrors && typeof fieldErrors === "string"
      ) ||
      newErrors.shiftConfig.some((configErrors: any) =>
        Object.values(configErrors).some((error) => error)
      );
    if (hasErrors) return false;
    if (formData.shiftConfig.length > 2 || allShifts.length) {
      let isDuplicate: any = hasDuplicates(formData.shiftConfig);
      if (isDuplicate) {
        setIsShowSnackbar(true);
        setSnackbarData({
          message: "Please check the shifts duplicates not allowed",
          color: "error",
        });
        return false;
      }
    }
    return !hasErrors;
  };

  const handleDigitExchangeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: any
  ) => {
    const { value } = event.target;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      handleChange(event, index);
    }
  };

  const handleDigitPercentage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: any
  ) => {
    const { value } = event.target;
    if (/^[0-9]*$/.test(value) && (value === "" || parseInt(value) <= 100)) {
      handleChange(event, index);
    }
  };

  const getFormattedRegions = (regions: any) => {
    const updtedRegions = regions.map((item: any) => ({
      shift_id: item?.shift_value.name,
      shift_price: item?.shift_price,
      isAdded:false,
      isDisabled:item?.shift_value.name==="Regular" ? true : false ,
    }));
    return updtedRegions;
  };

  const handleFormData = () => {
    if (formData?.shiftConfig?.length === dropdowns?.shifts?.length) {
      setAddBtnDisabled(true);
      return;
    }
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      shiftConfig: [
        ...prevFormData.shiftConfig,
        {
          shift_id: "",
          shift_price: "",
          isAdded: true,
        },
      ],
    }));

    setShowFields([...showFields, true]);
  };
  const addDynamicFields = () => {
    setDynamicFields((prev: any) => prev + 1);
    handleFormData();
  };
  const handleDeleteFields = (index: number) => {
    const updatedFields = [...formData.shiftConfig];
    updatedFields.splice(index, 1);
    setFormData({ ...formData, shiftConfig: updatedFields });

    const updatedShowFields = [...showFields];
    updatedShowFields.splice(index, 1);
    setShowFields(updatedShowFields);
  };

  const regionCurrency = (regionName: any) => {
    const region = dropdowns?.region?.find((data) => data.name === regionName);
    return region ? region.region_value : null;
  };

  const handleEditor = () => {
    if (formData?.shiftConfig?.length === dropdowns?.shifts?.length) {
      setAddBtnDisabled(true);
    
    }
    setIsEditMode(false);
  };


  return (
    <div style={{ marginTop: "20px" }}>
      {isloading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "72vh", // Example height to center vertically
          }}
        >
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <>
          <Grid
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              // marginRight: "100px",
              width: "100%",
            }}
          >
            {allShifts.length !== 0 && (
              <ReusableButton
                text="Update Shift Configuration"
                style={{ backgroundColor: "#21D4FD" }}
                onClick={handleEditor}
              />
            )}
          </Grid>

          <Box
            sx={{
              flexGrow: 1,
              marginBottom: "15px",
              // backgroundColor: "aliceblue",
              // padding: "12px",
              // width: "95%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    onClick={addDynamicFields}
                    endIcon={<AddIcon />}
                    variant="contained"
                    disabled={isEditMode || addBtnDisabled}
                  >
                    Add Shift Configuration
                  </Button>
                </Grid>
                {formData?.shiftConfig?.map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    {showFields[index] && (
                      <>
                        <Grid item xs={12} sm={6} md={5.5}>
                          <TextField
                            select
                            label="Shift name"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="shift_id"
                            value={item?.shift_id}
                            onChange={(event) => handleChange(event, index)}
                            error={!!errors.shiftConfig[index]?.shift_id}
                            helperText={errors.shiftConfig[index]?.shift_id}
                            disabled={isEditMode}
                          >
                            {shiftdropdown.length > 0 ? (
                              shiftdropdown.map((option: any) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No options available</MenuItem>
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5.5}>
                          <TextField
                            label="Amount per day"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="shift_price"
                            inputProps={{
                              maxLength: 6,
                            }}
                            value={
                              item?.shift_price
                            }
                            error={!!errors.shiftConfig[index]?.shift_price}
                            helperText={errors.shiftConfig[index]?.shift_price}
                            disabled={isEditMode || formData.shiftConfig[index]?.isDisabled}
                            onChange={(event) =>
                              handleDigitChange(event, index)
                            }
                          ></TextField>
                        </Grid>

                        {index !== 0 &&
                          (item.isAdded === undefined || item.isAdded) && (
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "10px",
                                marginTop: "20px",
                              }}
                            >
                              <IconButton
                                onClick={() => handleDeleteFields(index)}
                                disabled={isEditMode}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="delete_icon"
                                  height="25px"
                                  width="25px"
                                  // style={{ marginRight: "8px" }}
                                />{" "}
                              </IconButton>
                            </Grid>
                          )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Grid>

              <Grid
                container
                spacing={3}
                justifyContent="center"
                sx={{ marginTop: "15px" }}
              >
                <Grid item>
                  <ReusableButton
                    text={allShifts.length ? "Save" : "Create"}
                    //   style={{ backgroundColor: "#4CAF50" }}
                    endIcon={loader && <CircularProgress size="sm" />}
                    type="submit"
                    disabled={isEditMode}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </>
      )}

      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}
    </div>
  );
};

export default ProjectEstimator;
