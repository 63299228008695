import { Button, Container, Typography } from '@mui/joy';
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom';

const Error404Page = () => {
    const user_role= Cookies.get("user_role")
    const link= user_role==="Sales" ? "/" : "/admin-dashboard"
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        gap: 2,
      }}
    >
      
      <Typography level='h3' color='neutral' mb={1}>
        Oops! The page you're looking for isn't here.
      </Typography>
      <Typography level='body-lg'>
        You might have the wrong address, or the page may have moved.
      </Typography>
      <Button component={NavLink} to={link}>
        Go Home
      </Button>
    </Container>
  );
};

export default Error404Page;
