import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

 interface ReusableSnackbarProps {
  colour: any;
  isShowSnackbar: boolean;
  setIsShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}

const ReuseableSnackbar: React.FC<ReusableSnackbarProps>=({colour,isShowSnackbar,setIsShowSnackbar,message})=> {


  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsShowSnackbar(false);
  };

  return (
    <div>
      <Snackbar open={isShowSnackbar} autoHideDuration={4000} onClose={handleClose}   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert
          onClose={handleClose}
          severity={colour}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ReuseableSnackbar;