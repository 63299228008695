import React, { useEffect, useState } from "react";
import { Get, Put } from "../../../services/apiServices";
import { networkUrls } from "../../../services/networkUrls";
import downloadImage from "../../../assests/images/Group.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  Tab,
  TableFooter,
  Grid,
  TextField,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "./View.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import EditUser from "../Edituser";
import ReuseableSnackbar from "../../../components/common/Snackbar/ResueableSnackbar";
import { CircularProgress } from "@mui/joy";
import AbsyzLogo from "../../../assests/images/absyz logo.jpg";
import Versions from "../Versions";
import moment from "moment";


interface ProjectDetail {
  id: number;
  project_name: string;
  project_type: string;
  region: string;
  created_at: string;
  updated_at: string;
  projectItems?: ProjectItems[];
  regionDetails: {
    id: number;
    name: string;
    region_value: string;
  };
  currencyDetails: {
    id: number;
    name: string;
    region_value: string;
  };
}

interface ProjectSumDetail {
  no_of_resources: number;
  total_budgeted_hours: number;
  min_total_hr_rate: number;
  std_total_hr_rate: number;
}

interface ProjectItems {
  id: number;
  project_id: number;
  level: number;
  stream: number;
  no_of_resources: number;
  min_average_price: number;
  min_per_hr_rate: number;
  min_total_hr_rate: number;
  std_average_price: number;
  std_per_hr_rate: number;
  std_total_hr_rate: number;
  total_budgeted_hours: number;
  onsite: boolean;
  shift: ShiftObject;
  maximum_discount: number;
  status: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  projectStreams: ProjectStreams;
  projectLevels: ProjectLevels;
}

interface ProjectStreams {
  id: number;
  name: string;
}

interface ShiftObject {
  id: number;
  name: string;
}

interface ProjectLevels {
  id: number;
  name: string;
}

interface DropdownOption {
  region_value: any;
  id?: number;
  name: string;
  region?: string;
}
const dropdowniconstyles = {
  ".MuiSelect-icon": {
    color: "#1670C3",
  },
};

const View: React.FC<any> = (props) => {
  const { projectId, value, setValue, getAllProjects } = props;
  const [projectDetails, setProjectDetails] = useState<ProjectDetail[]>([]);
  const [snackbarData, setSnackbarData] = useState({ color: "", message: "" });
  const [projectSumDetails, setProjectSumDetails] = useState<
    ProjectSumDetail[]
  >([]);
  const [dropdowns, setDropdowns] = useState<Record<string, DropdownOption[]>>(
    {}
  );
  const [loader, setLoader] = useState(false);
  const [standardAveragePrice, setStandardAveragePrice] = useState<any>();
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(true);
  const [minimumAveragePrice, setMinimumAveragePrice] = useState<any>();
  const [maximumDiscount, setMaximumDiscount] = useState();
  const [currency, setCurrency] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [formData, setFormData] = useState<any>({
    opportunityName: "",
    region: "",
    opportunityType: "",
    // currency: "",
  });

  const [errors, setErrors] = useState<{
    opportunityName: string;
    region: string;
    opportunityType: string;
    [key: string]: string;
  }>({
    opportunityName: "",
    region: "",
    opportunityType: "",
  });

  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const getAllDropDowns = async () => {
    try {
      const response = await Get(networkUrls.getDropdownValues, true);
      if (response?.data?.api_status === 200) {
        setDropdowns(response?.data?.data);
      }
    } catch (error) {
      console.log("Error getting Dropdown values", error);
    }
  };

  const getSingleProject = async (id: any) => {
    setLoader(true);
    try {
      const response = await Get(networkUrls.getSingleProject + `${id}`, true);
      if (response?.data?.api_status === 200) {
        setProjectDetails([response?.data?.data.projectsFromDB]);
        setProjectSumDetails([response?.data?.data.sums]);
        setStandardAveragePrice(
          response?.data?.data.projectsFromDB.projectItems[0].std_average_price
        );
        setCurrency(
          response?.data?.data?.projectsFromDB?.currencyDetails?.region_value
        );

        setMinimumAveragePrice(
          response?.data?.data.projectsFromDB.projectItems[0].min_average_price
        );
        setMaximumDiscount(
          response?.data?.data.projectsFromDB.projectItems[0].maximum_discount
        );
        setFormData({
          opportunityName: response?.data?.data.projectsFromDB.project_name,
          region: response?.data?.data.projectsFromDB.regionDetails.name,
          opportunityType: response?.data?.data.projectsFromDB.project_type,
          
        });
      }
    } catch (error) {
      setLoader(false);
      console.log("Error getting Project Details", error);
    }
    setLoader(false);
    setIsDisabled(false)
  };

  useEffect(() => {
    if (projectId !== undefined) {
      getAllDropDowns();
      getSingleProject(projectId);
    }
  }, [projectId]);


  const getOP = (region: any, val: any) => {
    if (region === "INR") {
      return `₹${val}`;
    } else {
      return `$${val}`;
    }
  };

  const handleDownloadPDF = () => {
    if (!projectDetails) return;
    // const pdfContent = generatePDFContent();
    // const blob = new Blob([pdfContent], { type: "application/pdf" });
    // saveAs(blob, "project_costing.pdf");
    // generatePDF(projectDetails, projectSumDetails);
      // Usage example
  convertImageToBase64(AbsyzLogo, function(base64Image:any) {
    // Call your generatePDF function here
    generatePDF(projectDetails, projectSumDetails, base64Image);
  });
  };

  function convertImageToBase64(url:any, callback:any) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  

  

  const generatePDF = (
    projectDetails: ProjectDetail[],
    projectSumDetails: ProjectSumDetail[],
    imageData: string
  ) => {
    if (!projectDetails.length || !projectSumDetails.length) return;

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const doc = new jsPDF(orientation, unit, size);

    // Start Y position for content
    let yPos = 20;

    // Add title
    doc.setFontSize(12);
    const imageX = 275; // X coordinate
  const imageY = 30; // Y coordinate
  const imageWidth = 80; // Width of the image
  const imageHeight = 60; // Height of the image


  doc.addImage(imageData, 'JPEG', imageX, imageY, imageWidth, imageHeight);
    doc.text("PROJECT ESTIMATION", 250, 100);  
    
    doc.text(`Project Name : ${projectDetails[0].project_name}`, 38, 140);
    doc.text(`Project Type : ${projectDetails[0].project_type}`, 38, 160);
    doc.text(
      `Project Region : ${projectDetails[0].regionDetails.name}`,
      38,
      180
    );
    doc.text(
      `Standard Quote(Average) : ${regionCurrency(
        projectDetails[0]?.regionDetails?.name
      )} ${standardAveragePrice}`,
      38,
      200
    );
    doc.text(
      `Minimum Quote(Average) : ${regionCurrency(
        projectDetails[0]?.regionDetails?.name
      )} ${minimumAveragePrice}`,
      38,
      220
    );
    doc.text(`Maximum Discount : ${maximumDiscount} %`, 38, 240);
    doc.text(`Created At : ${moment(projectDetails[0].created_at).format('DD MMMM YYYY hh:mm:ss a')}`, 38, 260);
    doc.text(`Updated At : ${moment(projectDetails[0].updated_at).format('DD MMMM YYYY hh:mm:ss a')}`, 38, 280);



    yPos = 300;

    // Table headers and data
    const headers = [
      "Stream",
      "Level",
      "Total resources",
      "Shift",
      "Onsite",
      "Budgeted hours",
      "Per hour(Std price)",
      "Total(Std price)",
      "Per hour(Min price)",
      "Total(Min price)",
    ];

    // Calculate table data
    const tableData = [];
    projectDetails.forEach((project) => {
      project.projectItems?.forEach((item) => {
        tableData.push([
          item.projectStreams?.name || "",
          item.projectLevels?.name || "",
          item.no_of_resources.toString(),
          item.shift?.name,
          item.onsite === true ? "Yes" : "No",
          item.total_budgeted_hours.toString(),
          `${regionCurrency(project.regionDetails.name)} ${
            item.std_per_hr_rate
          }`,
          `${regionCurrency(
            project.regionDetails.name
          )} ${item.std_total_hr_rate?.toLocaleString()}`,
          `${regionCurrency(project.regionDetails.name)} ${
            item.min_per_hr_rate
          }`,
          `${regionCurrency(
            project.regionDetails.name
          )} ${item.min_total_hr_rate?.toLocaleString()}`,
        ]);
      });
    });

    // Add totals row
    const totalsRow = [
      "Totals",
      "",
      projectSumDetails[0].no_of_resources.toString(),
      "",
      "",
      projectSumDetails[0].total_budgeted_hours.toString(),
      "",
      `${regionCurrency(projectDetails[0].regionDetails?.name)} ${
        projectSumDetails[0].std_total_hr_rate
      }`,
      "",
      `${regionCurrency(projectDetails[0].regionDetails?.name)} ${
        projectSumDetails[0].min_total_hr_rate
      }`,
    ];
    tableData.push(totalsRow);

    // Set table position
    const startX = -60;
    const startY = yPos + 10;

    // Define column widths
    const columnWidths = [50, 30, 45, 40, 30, 45,  82, 72, 83, 73];

    // Create table with adjusted column widths

    const styles = {
      theme: "grid",
      fontSize: 8,
      cellPadding: 2,
      // textColor: [26, 48, 93],
      headStyles: {
        fillColor: [26, 48, 93],
        textColor: 255,
        fontSize: 10,
        halign: "center",
      },
      bodyStyles: {
        textColor: [26, 48, 93],
        fontSize: 8,
        valign: "middle",
        halign: "center",
      },
      alternateRowStyles: { fillColor: [245, 245, 245] },
      valign: "middle",
      halign: "center",
    };
    //@ts-ignore
    doc.autoTable({
      startX,
      startY,
      head: [headers],
      body: tableData,
      theme: "grid",
      margin: { top: 20 },
      styles,
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] },
        5: { cellWidth: columnWidths[5] },
        6: { cellWidth: columnWidths[6] },
        7: { cellWidth: columnWidths[7] },
        8: { cellWidth: columnWidths[8] },
        9: { cellWidth: columnWidths[9] },

      },
    });

    // Save the PDF with a specific name
    doc.save(`${projectDetails[0]?.project_name}-Ratecard.pdf`);
  };

  const regionCurrency = (regionName: any) => {
    if (!projectDetails.length) return null;
    const region = projectDetails[0]?.currencyDetails;
    return region ? region.region_value : null;
  };

  const data = {
    currency: "INR",
    headers: [
      "Stream",
      "Level",
      "Total resources",
      "Shift",
      "Onsite",
      "Budgeted hours",
      "Per hour",
      "Total",
      "Per hour",
      "Total",
    ],
    totals: {
      resources: projectSumDetails[0]?.no_of_resources,
      hours: projectSumDetails[0]?.total_budgeted_hours,
      standardTotal: projectSumDetails[0]?.std_total_hr_rate,
      minTotal: projectSumDetails[0]?.min_total_hr_rate,
    },
  };

  const currencyConvertor = async (id: number | undefined) => {
    setLoader(true);
    const payload = {
      project_id: projectDetails[0]?.id,
      region_id: id,
    };
    try {
      const response = await Put(networkUrls.currencyConvertor, payload, true);
      if (response?.response.api_status === 200) {
        setMessage(response?.response.message);
        setIsShowSnackbar(true);
        setSnackbarData({
          message: response?.response.message,
          color: "success",
        });
        setSnackBarStatus(true);
        getSingleProject(projectId);
      } else {
        setSnackbarData({
          message: response?.response.message,
          color: "error",
        });
      }
    } catch (error) {
      console.log("Error getting currency convertor", error);
    }
    setLoader(false);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    setCurrency(value);
    const regionId = dropdowns?.region?.filter(
      (arr: any) => arr.region_value === value
    );
    const id: number | undefined = regionId[0]?.id;
    // setFormData((prev: any) => ({
    //   ...prev,
    //   currency: value,
    // }));
    currencyConvertor(id);
  };

  const freezeFields = (value:any)=>{
    setIsDisabled(value)
  }

  const getUpdatedProjectDetails = async (id: any) => {
    getSingleProject(id);
    getAllProjects();
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      {!loader ? (
        <Grid container spacing={0} style={{ margin: "20px 10px" }}>
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography
              style={{
                color: "#1B305D",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              {projectDetails[0]?.project_name}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography sx={{ flexGrow: 1, color: "#ABAAAA" }}>
              Opportunity name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              autoComplete="off"
              size="small"
              name="opportunityName"
              sx={{ color: "#ABAAAA" }}
              placeholder="Opportunity name"
              onChange={handleFormChange}
              value={formData.opportunityName}
              error={!!errors.opportunityName}
              helperText={errors.opportunityName}
              disabled={value === "2" || isDisabled || value==="3"}
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={3.5}>
            <Typography sx={{ flexGrow: 1, color: "#ABAAAA" }}>
              Region
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              size="small"
              name="region"
              onChange={handleFormChange}
              value={formData.region}
              error={!!errors.region}
              helperText={errors.region}
              sx={dropdowniconstyles}
              disabled={value === "2" || isDisabled  || value==="3"}
            >
              <MenuItem value="" disabled>
                Select Region
              </MenuItem>
              {dropdowns?.region?.map((option: any) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={3.5}>
            <Typography sx={{ flexGrow: 1, color: "#ABAAAA" }}>
              Opportunity type
            </Typography>
            <TextField
              select
              // label="Stream"
              placeholder="Opportunity Type"
              fullWidth
              variant="outlined"
              size="small"
              name="opportunityType"
              value={formData.opportunityType}
              onChange={handleFormChange}
              error={!!errors.opportunityType}
              helperText={errors.opportunityType}
              sx={dropdowniconstyles}
              disabled={value === "2" || isDisabled  || value==="3"}
            >
              {dropdowns?.projectType?.map((option: any) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <CircularProgress variant="solid" />
          </Box>
        </>
      )}

      {loader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            position: "relative",
            top: "298px",
          }}
        >
          <CircularProgress variant="solid" />
        </Box>
      ) : (
        <>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  style={{ textTransform: "capitalize" }}
                  label="Resources"
                  value="1"
                />
                <Tab
                  style={{ textTransform: "capitalize" }}
                  label="Commercials"
                  value="2"
                />
                 <Tab
                  style={{ textTransform: "capitalize" }}
                  label="Versions"
                  value="3"
                />
              </TabList>
            </Box>

            <TabPanel value="1">
              <EditUser
                projectId={projectId}
                formData={formData}
                setFormData={setFormData}
                errors={errors}
                setErrors={setErrors}
                getUpdatedProjectDetails={getUpdatedProjectDetails}
                freezeFields={freezeFields}
              />
            </TabPanel>
            <TabPanel value="2">
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                p={2}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "20px",
                    border: "1px solid #1670C3",
                    padding: "4px 10px",
                  }}
                >
                  {/* {dropdowns.region[0].region_value} */}
                  <Typography style={{ color: "#757575" }}>
                    Currency &nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>

                  {dropdowns?.region && dropdowns.region.length > 0 && (
                    <Select
                      value={currency}
                      variant="standard"
                      disableUnderline
                      name="currency"
                      style={{ color: "#1B305D", fontWeight: "700",top:"2px" }}
                      onChange={handleChange}
                    >
                      {dropdowns.region.map((option: any) => (
                        <MenuItem
                          key={option.region_value}
                          value={option.region_value}
                        >
                          {option.region_value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
                <div
                  style={{ marginLeft: "30px", cursor: "pointer",marginTop:"10px" }}
                  onClick={handleDownloadPDF}
                >
                  <img
                    src={downloadImage}
                    alt="download"
                    height="25px"
                    width="25px"
                  />
                </div>
              </Box>
              <TableContainer
                component={Paper}
                style={{ marginBottom: "20px" }}
              >
                <Table className="content" style={{ border: "none" }}>
                  <TableHead className="blue">
                    <TableRow>
                      <TableCell colSpan={6}></TableCell>
                      <TableCell
                        className="sub-content"
                        colSpan={2}
                        align="center"
                      >
                        Standard price
                      </TableCell>
                      <TableCell
                        className="sub-content"
                        colSpan={2}
                        align="center"
                      >
                        Minimum price
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {data.headers.map((header, index) => (
                        <TableCell key={index} align="center">
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.map((project, projectIndex) =>
                      project.projectItems?.map((item, index) => (
                        <TableRow key={`${projectIndex}-${index}`}>
                          <TableCell align="center">
                            {item.projectStreams?.name}
                          </TableCell>
                          <TableCell align="center">
                            {item.projectLevels?.name}
                          </TableCell>
                          <TableCell align="center">
                            {item.no_of_resources}
                          </TableCell>
                          <TableCell align="center">
                            {item.shift?.name}
                          </TableCell><TableCell align="center">
                            {item.onsite ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="center">
                            {item.total_budgeted_hours}
                          </TableCell>
                          <TableCell align="center">
                            <span>
                              {regionCurrency(project.currencyDetails.name)}{" "}
                              {item.std_per_hr_rate?.toLocaleString('en-US')}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            {regionCurrency(project.currencyDetails.name)}{" "}
                            {item.std_total_hr_rate?.toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {regionCurrency(project.currencyDetails.name)}{" "}
                            {item.min_per_hr_rate}
                          </TableCell>
                          <TableCell align="center">
                            {regionCurrency(project.currencyDetails.name)}{" "}
                            {Number(item.min_total_hr_rate)?.toLocaleString('en-US')}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                  <TableFooter className="footer-content">
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        Totals
                      </TableCell>
                      <TableCell align="center">
                        {data.totals.resources}
                      </TableCell>
                      <TableCell align="center" colSpan={2}></TableCell>
                      <TableCell align="center">{data.totals.hours}</TableCell>
                      {/* <TableCell align="center" colSpan={2}></TableCell> */}
                      <TableCell align="center" colSpan={2}>
                        {regionCurrency(projectDetails[0]?.regionDetails.name)}{" "}
                        {data.totals.standardTotal?.toLocaleString('en-US')}
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        {regionCurrency(projectDetails[0]?.regionDetails.name)}{" "}
                        {data.totals.minTotal?.toLocaleString('en-US')}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Grid container spacing={0}>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "20px",
                  }}
                >
                  <Grid xs={3}>
                    <Typography style={{ color: "#757575" }}>
                      Standard quote (Average)
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        font: "Inter",
                        color: "#1B305D",
                        backgroundColor: "#EBEFF5",
                        fontWeight: "750",
                        height: "38px",
                      }}
                    >
                      <span>
                        {`${regionCurrency(
                          projectDetails[0]?.regionDetails?.name
                        )} ${standardAveragePrice?.toLocaleString('en-US')}`}{" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ fontWeight: "200" }}>
                          {" "}
                          {projectDetails[0]?.project_type ===
                          "Time and Material Blended Rate"
                            ? "(Per hr)"
                            : ""}
                        </span>
                      </span>
                    </div>
                  </Grid>
                  <Grid xs={0.25}></Grid>
                  <Grid xs={3}>
                    <Typography style={{ color: "#757575" }}>
                      Max discount
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        font: "Inter",
                        color: "#1B305D",
                        backgroundColor: "#FCFDFD",
                        width: "64px",
                        border: "1px solid #EBEFF5",
                        fontWeight: "750",
                        height: "38px",
                      }}
                    >
                      <span>{`${maximumDiscount} %`}</span>
                    </div>
                  </Grid>
                  <Grid xs={5.75}></Grid>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "20px",
                  }}
                >
                  <Grid xs={3}>
                    <Typography style={{ color: "#757575" }}>
                      Minimum quote (Average)
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        font: "Inter",
                        color: "#1B305D",
                        backgroundColor: "#EBEFF5",
                        fontWeight: "750",
                        height: "38px",
                      }}
                    >
                      <span>
                        {`${regionCurrency(
                          projectDetails[0]?.regionDetails?.name
                        )} ${minimumAveragePrice?.toLocaleString('en-US')}`}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ fontWeight: "200" }}>
                          {projectDetails[0]?.project_type ===
                          "Time and Material Blended Rate"
                            ? "(Per hr)"
                            : ""}
                        </span>
                      </span>
                    </div>
                  </Grid>
                  <Grid xs={0.25}></Grid>
                  <Grid xs={3}>
                    <Typography style={{ color: "#757575" }}>
                      Min discount
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        font: "Inter",
                        color: "#757575",
                        backgroundColor: "#FCFDFD",
                        height: "38px",
                      }}
                    >
                      <span>No discount on this quote</span>
                    </div>
                  </Grid>
                  <Grid xs={5.75}></Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Versions  projectDetails={projectDetails}/>
            </TabPanel>
          </TabContext>
        </>
      )}

      {isShowSnackbar && (
        <ReuseableSnackbar
          isShowSnackbar={isShowSnackbar}
          setIsShowSnackbar={setIsShowSnackbar}
          colour={snackbarData.color}
          message={snackbarData.message}
        />
      )}
    </Box>
  );
};

export default View;
