import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Navbar from "./components/common/Navbar/Navbar";
import ProtectedRoute from "./components/common/ProtectedRoute/ProtectedRoute";
import PricingEstimator from "./Pages/Admin/PricingEstimator/PricingEstimator";
import Projects from "./Pages/Admin/Projects/Projects";
import UserDashboard from "./Pages/User/UserDashboard";
import View from "./Pages/User/ProjectView/View";
import ConnectLogin from "./Pages/ConnectLogin/ConnectLogin";
import User from "./Pages/User/User";
import "./App.css"
import {FileUploadComponent} from "./Pages/Admin/FileUpload/FileUpload";
import AdminDashBoard from "./Pages/Admin/AdminDashboard/AdminDashboard";
import Error404Page from "./components/common/NotFound/NotFound";


const App = () => {
  return (
    <div >
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/connect-login" element={<ConnectLogin />} />

          {/*  User Routes*/}

          <Route element={<ProtectedRoute allowedRoles={["Sales"]} />}>
              <Route path="/projects" element={<Projects />} />
              <Route path="/" element={<User />} />
              <Route path="/view" element={<View />} />
              <Route path="*" element={<Error404Page/>}/>
          </Route>

          {/* Admin Routes */}
          <Route element={<ProtectedRoute allowedRoles={["Finance","SystemAdmin"]} />}>
            {/* <Route element={<Navbar />}> */}
              {/* <Route path="/admin" element={<PricingEstimator />} /> */}
              <Route path="/admin-dashboard" element={<AdminDashBoard />} />
              <Route path="*" element={<Error404Page/>}/>
              {/* <Route path="/file-upload" element={<FileUploadComponent />} /> */}
            {/* </Route> */}
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
