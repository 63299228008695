import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ allowedRoles }: any) => {
  const token = Cookies.get("accessToken");
  const userRole: any = Cookies.get("user_role");
  if (!userRole) {
    return <Navigate to="/login" />;
  } 
  else if (!token) return <Navigate to="/login" />;
  else if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
