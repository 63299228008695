export const networkUrls = {
    sendOtp: "/send-otp",
     verifyOtp:"/verify-otp",
     createProject:"/project/create-project",
     getAllProjects:"/project/get-all-projects",
     getDropdownValues:"/project/all-drop-downs",   
     getSingleProject:"/project/get-single-project/",
     editProject:"/project/edit-project/",
     getAllConfigurations:"/admin/get-all-configs",
     createConfig:"/admin/admin-config",
     editConfig:"/admin/edit-config",
     createShift:"/admin/add-shift-config",
     getAllShifts:"/admin/get-shift-config",
     editShift:"/admin/edit-shift-config",
     fileUpload :"/admin/uploadexcel",
     getVersion:"/project/get-versions-data",
     currencyConvertor:"/project/currency-converter",
     connectLogin:"/auth/connect-login"
};
  