import { Button } from '@mui/material';
import React from 'react'

interface ButtonProps{
    text: string;
    style?: React.CSSProperties;
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    disabled?: boolean
    endIcon?: React.ReactNode
}

const ReusableButton :React.FC<ButtonProps>= ({text,style,type,onClick, disabled ,endIcon }) => {
  return (
    <div>
        <Button variant="contained"  sx={{ ...style }}  type={type} onClick={onClick} disabled={disabled} endIcon={endIcon}>{text}</Button>
    </div>
  )
}

export default ReusableButton